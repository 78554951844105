import React, { useEffect } from "react";
import { useState } from "react";
import Countdown, { zeroPad } from "react-countdown";
import { Link } from "react-router-dom";
import Footer from "../components/Footer.js";
import Navbar from "../components/Navbar.js";
import Web3 from "web3";
import LotterytokenABI from "../ABI/Lotterytoken.json";
import config from "../config/config";
// import LotteryABI from "../ABI/Lottery.json";
import bonjourapi from "../ABI/bonjourABI";
import BigNumber from "bignumber.js";

import moment from "moment";
import "../App.css";
import isEmpty from "../config/isEmpty";
import { useNavigate } from "react-router-dom";
import { toastAlert } from "../helper/toastAlert";

import { toast } from "react-toastify";
import isValidation from "../pages/seperate/validation";
import {
  addLottery,
  addLotterySettings,
  getAddress,
  updateTicketPrice,
  updatePrize,
  getlotterylist,
} from "../action/admin";
import XlsxPopulate from "xlsx-populate";

import { saveAs } from "file-saver";

import Lottarylist from "../pages/Lottarylist";

import {
  getCurAddr,
  updateregister,
  filter_event,
  getuserrecords,
  claim_list,
  add_Address,
  remove_Address,
} from "../action/Apicontroller";

import lotteryABI from "../ABI/Lottery.json";
import {
  Multicall,
  ContractCallResults,
  ContractCallContext,
} from "ethereum-multicall";
// toast.configure();

let toasterOption = config.toasterOption;

function ScrollToTopOnMount() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return null;
}

const initialFormValue = {
  operator: "",
  treasury: "",
  injector: "",
};

const initialFormValue1 = {
  lotteryId: "",
  endTime: "",
  ticketPice: "",
  discount: 300,
  rew1: "",
  rew2: "",
  rew3: "",
  rew4: "",
  rew5: "",
  rew6: "",
  fees: 3000,
};

const initialFormValue2 = {
  ticketprize: "",
  AEDprize: "",
};

export default function Settings(props) {
  const [open, setOpen] = useState(true);
  const [anchorEl, setAnchorEl] = useState(null);
  const [provider, setprovider] = React.useState();
  const [address, setaddress] = React.useState();

  // const [UserAccountAddr, Set_UserAccountAddr] = React.useState("");
  // const [UserAccountBal, Set_UserAccountBal] = React.useState(0);
  // const [WalletConnected, Set_WalletConnected] = React.useState("false");
  // const [AddressUserDetails, Set_AddressUserDetails] = useState({});
  // const [Accounts, Set_Accounts] = React.useState("");
  ////

  const [progress, setprogress] = useState("false");
  const [withdrawprogress, setwithdrawprogress] = useState("false");
  const [earnprogress, setearnprogress] = useState("false");
  const [minprogress, setminprogress] = useState("false");
  const [records, setrecords] = useState([]);
  const [withamt, setwithamt] = useState(0);
  const [withaddress, setwithaddress] = useState("");
  const [earnlimit, setearnlimit] = useState(0);
  const [minval, setminval] = useState(0);
  const [globalpoolamount, setglobalpoolamount] = useState(0);
  const [globalshare, setglobalshare] = useState(0);
  const [usertableheader, setusertableheader] = useState([
    "SNO",
    "USER ID",
    "ADDRESS",
    "Invest_Amount",
    "Re-Invest_Amount",
    "Claimed_Amount",
    "Global_Claimed_Amount",
  ]);
  const [tableheader, settableheader] = useState([
    "SNO",
    "USER ID",
    "ADDRESS",
    "Activity",
    "Amount",
    "DATE",
  ]);
  const [tabledata, settabledata] = useState([]);
  const [usertabledata, setusertabledata] = useState([]);
  const [claimprogress, setclaimprogress] = useState("false");
  const [removeprogress, setremoveprogress] = useState("false");
  const [claimaddress, setclaimaddress] = useState("");
  const [claimlist, setclaimlist] = useState([]);
  var today = new Date();
  var dd = today.getDate();
  var mm = today.getMonth() + 1; //January is 0!
  var yyyy = today.getFullYear();
  if (dd < 10) {
    dd = "0" + dd;
  }
  if (mm < 10) {
    mm = "0" + mm;
  }

  today = yyyy + "-" + mm + "-" + dd;
  let dateafter = parseInt(dd) + 1;
  let nextday = yyyy + "-" + mm + "-" + dateafter;
  const [currentdate, setcurrentdate] = useState(today);
  const [startdate, setstartdate] = useState("");
  const [enddate, setenddate] = useState("");
  const [currentevent, setcurrentevent] = useState("all");
  const [filterrec, setfilterrec] = useState([]);
  const [formValue, setFormValue] = useState(initialFormValue);
  const [validateError, setValidateError] = useState({});
  const [submitButton, setsubmitButton] = useState(false);
  const { operator, injector, treasury } = formValue;
  const [formValue1, setFormValue1] = useState(initialFormValue1);
  // const [formValue2, setFormValue2] = useState(initialFormValue2);
  const [status, setstatus] = useState();

  const [ticketprize, setticketprize] = useState("");

  const [AEDprize, setAEDprize] = useState("");
  const {
    lotteryId,
    endTime,
    ticketPice,
    discount,
    rew1,
    rew2,
    rew3,
    rew4,
    rew5,
    rew6,
    fees,
  } = formValue1;
  // const [updatedticketprize, setupdatedticketprize] = useState("");

  // const { ticketprize, AEDprize } = formValue2;

  const history = useNavigate();

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleDrawerOpen = () => {
    setOpen(!open);
  };

  useEffect(() => {
    getaddress();
    getdetails();
    getclaimlist();
    getdowlods();
    getupdatePrize();
    fetchrecord(today, nextday, "all");
    getLotteryList1();
    // if (provider && address) {
    // }
  }, []);

  // function
  const onChange = (e) => {
    e.preventDefault();
    const { id, value } = e.target;
    let formData = { ...formValue, ...{ [id]: value } };
    setFormValue(formData);
    console.log(formValue);
  };

  const getLotteryList1 = async () => {
    var test = await getlotterylist();
    const lastItem = test.userValue[test.userValue.length - 1];
    console.log(lastItem?.status, "lastItem......");
    setstatus(lastItem?.status);
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    setsubmitButton(true);
    let datas = await getCurAddr();
    console.log(datas, localStorage.getItem("account"), "local");
    if (
      datas &&
      datas.address &&
      datas.address != undefined &&
      datas.address != null &&
      datas.address != "" &&
      localStorage.getItem("account")
    ) {
      let web3 = new Web3(
        datas &&
        datas.provider &&
        datas.provider != null &&
        datas.provider != undefined &&
        datas.provider != ""
          ? datas.provider
          : window.ethereum
      );

      // var currAddr = window.web3.eth.defaultAccount;
      var currAddress = await web3.eth.getAccounts();
      if (!currAddress) {
        toast.warning("OOPS!..connect Your Wallet", toasterOption);
        setsubmitButton(false);
        return false;
      }

      console.log(
        config.ContractAddress,
        config.lotterAdmin,
        currAddress[0],
        "pankuku"
      );

      if (currAddress[0] != config.lotterAdmin) {
        toast.error("Please  Connect with Owner Wallet", toasterOption);
        setsubmitButton(false);
        return false;
      }

      const Contract = new web3.eth.Contract(
        lotteryABI,
        config.ContractAddress
      );
      if (
        !web3.utils.isAddress(operator) ||
        !web3.utils.isAddress(treasury) ||
        !web3.utils.isAddress(injector)
      ) {
        toast.warning("Check Your address Format", toasterOption);
        setsubmitButton(false);
        return false;
      }
      console.log(formValue, "formValue");
      var transactionHash = "";
      var result = await Contract.methods
        .setOperatorAndTreasuryAndInjectorAddresses(
          operator,
          treasury,
          injector
        )
        .send({ from: currAddress[0] })
        .then((output) => {
          console.log(output, "output");
          if (output.transactionHash) {
            transactionHash = output.transactionHash;
          } else {
            transactionHash = output;
          }
          console.log(output);
        })
        .catch((error) => {
          console.log(error);
          setsubmitButton(false);
          return false;
        });
      // console.log(result.transactionHash,'transactionhash')

      // if (result.transactionHash) {
      //   transactionHash = result.transactionHash;
      // } else {
      //   transactionHash = result;
      // }

      let reqData = {
        operator,
        treasury,
        injector,
        transactionHash,
      };

      let { error } = await addLotterySettings(reqData);

      if (isEmpty(error)) {
        setsubmitButton(false);
        toast.success("Settings updated successfully", toasterOption);
      } else {
        setsubmitButton(false);
        setValidateError(error);
      }
      console.log("error");
    } else {
      toast.warning("OOPS!..connect Your Wallet", toasterOption);
      setsubmitButton(false);
      return false;
    }
  };

  async function getaddress() {
    var data = await getAddress();
    console.log(data, "datadatadatadata");
    if (data && data.userValue != undefined) {
      let formdata = {};
      formdata["operator"] = data.userValue.operator;
      formdata["treasury"] = data.userValue.treasury;
      formdata["injector"] = data.userValue.injector;
      setFormValue(formdata);
    }
  }

  async function convert(n) {
    try {
      var sign = +n < 0 ? "-" : "",
        toStr = n.toString();
      if (!/e/i.test(toStr)) {
        return n;
      }
      var [lead, decimal, pow] = n
        .toString()
        .replace(/^-/, "")
        .replace(/^([0-9]+)(e.*)/, "$1.$2")
        .split(/e|\./);
      return +pow < 0
        ? sign +
            "0." +
            "0".repeat(Math.max(Math.abs(pow) - 1 || 0, 0)) +
            lead +
            decimal
        : sign +
            lead +
            (+pow >= decimal.length
              ? decimal + "0".repeat(Math.max(+pow - decimal.length || 0, 0))
              : decimal.slice(0, +pow) + "." + decimal.slice(+pow));
    } catch (err) {
      return 0;
    }
  }

  const onchange2 = (e) => {
    if (e.target.name == "time") {
      const { id, value } = e.target;
      console.log(value, "suryaa");
      // var datatime = Date.parse(value);
      const datatime = moment(value).unix();
      console.log(datatime, "suryaa");
      let formData = { ...formValue1, ...{ [id]: datatime } };
      console.log(formData, "suryaa");
      setFormValue1(formData);
    }
  };

  const onChange1 = (e) => {
    e.preventDefault();
    const { id, value } = e.target;
    let formData = { ...formValue1, ...{ [id]: value } };
    setFormValue1(formData);
    console.log(formValue1);
  };

  const handleFormSubmit2 = async () => {
    if (ticketprize == "" || undefined || null) {
      toast.warning("Required ticket price", toasterOption);
      return false;
    } else if (isNaN(ticketprize)) {
      toast.warning("Invalid ticket price", toasterOption);
      return false;
    } else if (AEDprize == "" || undefined || null) {
      toast.warning("Required AED price", toasterOption);
      return false;
    } else if (isNaN(AEDprize)) {
      toast.warning("Invalid AED price", toasterOption);
      return false;
    }
    let reqData = {
      ticketprize,
      AEDprize,
    };

    console.log(reqData, "reqData");
    const result = await updateTicketPrice(reqData);
    if (result) {
      // alert("Succefully updated");
      toast.success("Token Price Updated successfully", toasterOption);
    }
    console.log(result, "result");
  };

  async function getupdatePrize() {
    const result = await updatePrize();
    console.log(result, "result************");
    setticketprize(result.result.result[0]?.ticketprize);
    setAEDprize(result.result.result[0]?.AEDprize);
  }

  const handleFormSubmit1 = async (e) => {
    console.log(status, "status**");
    if (status == 0) {
      toast.warning("Lottery is not closed", toasterOption);
    } else {
      e.preventDefault();
      let reqData = {
        endTime,
        ticketPice,
        rew1,
        rew2,
        rew3,
        rew4,
        rew5,
        rew6,
      };
      console.log(reqData, "formdata");
      let error = isValidation(reqData);
      console.log(error, "errors");
      if (!isEmpty(error)) {
        setValidateError(error);
        // toast.warning("Please Enter Required Fields", toasterOption);
        return false;
      }
      setsubmitButton(true);
      var arra = [];
      arra.push(parseInt(rew1 * 100));
      arra.push(parseInt(rew2 * 100));
      arra.push(parseInt(rew3 * 100));
      arra.push(parseInt(rew4 * 100));
      arra.push(parseInt(rew5 * 100));
      arra.push(parseInt(rew6 * 100));

      let datas = await getCurAddr();

      if (
        !(
          datas &&
          datas.address &&
          datas.address != undefined &&
          datas.address != null &&
          datas.address != "" &&
          localStorage.getItem("account")
        )
      ) {
        toast.warning("OOPS!..connect Your Wallet", toasterOption);
        setsubmitButton(false);
        return false;
      }
      let web3 = new Web3(
        datas &&
        datas.provider &&
        datas.provider != null &&
        datas.provider != undefined &&
        datas.provider != ""
          ? datas.provider
          : window.ethereum
      );

      var currAddr = await web3.eth.getAccounts();
      console.log(currAddr, "web3");
      if (!currAddr) {
        toast.warning("OOPS!..connect Your Wallet", toasterOption);
        setsubmitButton(false);
        return false;
      }
      if (
        parseInt(rew1 * 100) +
          parseInt(rew2 * 100) +
          parseInt(rew3 * 100) +
          parseInt(rew4 * 100) +
          parseInt(rew5 * 100) +
          parseInt(rew6 * 100) >
          10000 ||
        parseInt(rew1 * 100) +
          parseInt(rew2 * 100) +
          parseInt(rew3 * 100) +
          parseInt(rew4 * 100) +
          parseInt(rew5 * 100) +
          parseInt(rew6 * 100) <
          10000
      ) {
        toast.warning("Total split up must be 100", toasterOption);
        setsubmitButton(false);
        return false;
      }

      var currentDate = new Date();

      // var timeMIn = new Date(currentDate.setMinutes(currentDate.getHours() + 4));
      var timeMIn = new Date();

      // var timeMIn = new Date(
      //   currentDate.setHours((currentDate.getHours()) + 4)
      // );

      var timeMax = new Date(currentDate.setDate(currentDate.getDate() + 4));

      var endDate = new Date(endTime*1000);

      console.log(endDate, "endDate");
      console.log(endDate, timeMIn, timeMax, "saranTestingLOggggk");

      var timeMIn = currentDate.getMinutes();
      var endMinute = moment(endDate).format("mm");

      console.log(endMinute, "endMinute");
      var minutes = endMinute - timeMIn;
      var n = Math.abs(minutes);
      console.log(n, "minutes");
      if (n < 5) {
        toast.warning("It must be greater than 5 Minutes", toasterOption);
        setsubmitButton(false);
        return false;
      } else if (endDate > timeMax) {
        toast.warning("It must be lesser than 4 days", toasterOption);
        setsubmitButton(false);
        return false;
      }

      const Contract = new web3.eth.Contract(
        lotteryABI,
        config.ContractAddress
      );
      let convertamout = ticketPice * 1000000000000000000;
      var amountFIn = await convert(convertamout);

      console.log(endTime, amountFIn.toString(), 2000, arra, 3000, "llll");
      const timeanddate =
      endTime /
      (1000)
      var result = await Contract.methods.startLottery(endTime, amountFIn.toString(), 2000, arra, 3000)
          .send({ from: currAddr[0] })
          .then(async (result) => {
            var ticketId = result.events.LotteryOpen.returnValues.lotteryId;

            var transactionHash = "";

            if (result.transactionHash) {
              transactionHash = result.transactionHash;
            } else {
              transactionHash = result;
            }

            let reqData = {
              ticketId,
              endTime,
              ticketPice,
              discount,
              rew1,
              rew2,
              rew3,
              rew4,
              rew5,
              rew6,
              fees,
              transactionHash,
              endDate,
            };

            let { error } = await addLottery(reqData);

            setsubmitButton(false);
            toast.success("Lottery updated successfully", toasterOption);

            setTimeout(() => {
              window.location.reload();
            }, 4000);
            // history.push("/LotteryList");
          })
          .catch((error) => {
            setsubmitButton(false);
            setValidateError(error);
            toast.warn("Ooops! Rejected", toasterOption);
          });
    }
  };

  async function empty() {}

  function getSheetData(data, header) {
    var fields = Object.keys(data[0]);
    var sheetData = data.map(function (row) {
      return fields.map(function (fieldName) {
        return row[fieldName] ? row[fieldName] : "";
      });
    });
    sheetData.unshift(header);
    return sheetData;
  }

  async function getclaimlist() {
    let _records = await claim_list();
    if (_records && _records.data && _records.data.data) {
      setclaimlist(_records.data.data);
    } else {
      setclaimlist([]);
    }
  }

  async function getdowlods() {
    let rec = await getuserrecords();
    let record = [];
    for (let i in rec?.data?.data) {
      var obj_rec = {
        SNO: parseInt(i) + 1,
        UserId: rec.data.data[i].userid,
        Address: rec.data.data[i]._id.address,
        Investamt: rec.data.data[i].joined,
        reInvestamt: rec.data.data[i].reinvest,
        //reTopup : (parseFloat(rec.data.data[i].retopup1)+parseFloat(rec.data.data[i].retopup2)/1e18).toFixed(2),
        claim: (
          parseFloat(rec.data.data[i].claim1) +
          parseFloat(rec.data.data[i].claim2) / 1e18
        ).toFixed(2),
        globalclaim: rec.data.data[i].globalclaim,
        //Date : moment(rec.data.data[i].createdAt).format('MMM Do YYYY, h:mm:ss A')
      };
      record.push(obj_rec);
      if (parseInt(i) + 1 == rec.data.data.length) {
        setusertabledata(record);
      }
    }
  }

  async function saveAsExcel() {
    XlsxPopulate.fromBlankAsync().then(async (workbook) => {
      const sheet1 = workbook.sheet(0);
      const sheetData = getSheetData(tabledata, tableheader);
      const totalColumns = sheetData[0].length;
      sheet1.cell("A1").value(sheetData);
      const range = sheet1.usedRange();
      const endColumn = String.fromCharCode(64 + totalColumns);
      sheet1.row(1).style("bold", true);
      sheet1.range("A1:" + endColumn + "1").style("fill", "BFBFBF");
      range.style("border", true);
      return workbook.outputAsync().then((res) => {
        saveAs(res, "file.xlsx");
      });
    });
  }

  async function saveuserAsExcel() {
    XlsxPopulate.fromBlankAsync().then(async (workbook) => {
      const sheet1 = workbook.sheet(0);
      const sheetData = getSheetData(usertabledata, usertableheader);
      const totalColumns = sheetData[0].length;
      sheet1.cell("A1").value(sheetData);
      const range = sheet1.usedRange();
      const endColumn = String.fromCharCode(64 + totalColumns);
      sheet1.row(1).style("bold", true);
      sheet1.range("A1:" + endColumn + "1").style("fill", "BFBFBF");
      range.style("border", true);
      return workbook.outputAsync().then((res) => {
        saveAs(res, "file.xlsx");
      });
    });
  }

  const withamtchange = (e) => {
    setwithamt(e.target.value);
  };

  const withaddrchange = (e) => {
    setwithaddress(e.target.value);
  };

  const earnlimitchange = (e) => {
    setearnlimit(e.target.value);
  };

  const minvalchange = (e) => {
    setminval(e.target.value);
  };

  const claimaddresschange = (e) => {
    setclaimaddress(e.target.value);
  };

  const globalsharechange = (e) => {
    setglobalshare(e.target.value);
  };

  const startdatechange = async (e) => {
    setstartdate(e.target.value);
    fetchrecord(e.target.value, enddate, currentevent);
  };

  const enddatechange = async (e) => {
    setenddate(e.target.value);
    fetchrecord(startdate, e.target.value, currentevent);
  };

  const selectchange = async (e) => {
    setcurrentevent(e.target.value);
    fetchrecord(startdate, enddate, e.target.value);
  };

  async function getdetails() {
    let datas = await getCurAddr();
    if (
      datas &&
      datas.provider &&
      datas.provider != null &&
      datas.provider != undefined &&
      datas.provider != ""
    ) {
      let web3call = new Web3(
        datas &&
        datas.provider &&
        datas.provider != null &&
        datas.provider != undefined &&
        datas.provider != ""
          ? datas.provider
          : window.ethereum
      );
      try {
        const multicall = new Multicall({
          web3Instance: web3call,
        });
        var contractCallContext = [
          {
            reference: "details",
            contractAddress: config.bonjourcontract,
            abi: bonjourapi,
            calls: [
              {
                reference: "earnLimit",
                methodName: "earnLimit",
                methodParameters: [],
              },
              {
                reference: "getAllglobalPoolAddress",
                methodName: "getAllglobalPoolAddress",
                methodParameters: [],
              },
              {
                reference: "minWithdrawAmount",
                methodName: "minWithdrawAmount",
                methodParameters: [],
              },
              {
                reference: "globalPoolShare",
                methodName: "globalPoolShare",
                methodParameters: [],
              },
            ],
          },
        ];
        const results = await multicall.call(contractCallContext);
        var minWithdrawAmount = await getFormatMulticall(
          results,
          "minWithdrawAmount",
          0
        );
        var getAllglobalPoolAddress = await getFormatMulticall(
          results,
          "getAllglobalPoolAddress",
          0
        );
        var globalPoolShare = await getFormatMulticall(
          results,
          "globalPoolShare",
          0
        );
        let global_amt = await bignumber(globalPoolShare[0]);
        setglobalpoolamount(global_amt);
        setrecords(getAllglobalPoolAddress);
        var earnLimit = await getFormatMulticall(results, "earnLimit", 0);
        earnLimit = await bignumber(earnLimit[0]);
        setearnlimit(earnLimit);
        var minamt = await getFormatMulticall(results, "minWithdrawAmount", 0);
        minamt = await bignumber(minamt[0]);
        minamt = parseFloat(minamt) / 1e18;
        setminval(minamt);
      } catch (Err) {
        console.log(Err, "======err");
      }
    }
  }

  function getFormatMulticall(results, name, pos) {
    try {
      var index =
        results &&
        results.results &&
        results.results.details &&
        results.results.details.callsReturnContext &&
        results.results.details.callsReturnContext.findIndex(
          (val) => val.reference == name
        );
      var returnVal =
        results.results.details.callsReturnContext[index] &&
        results.results.details.callsReturnContext[index].returnValues
          ? results.results.details.callsReturnContext[index].returnValues
          : "";
      return returnVal;
    } catch (err) {
      console.log(err, "==+++++++");
      return "";
    }
  }

  async function bignumber(inputs) {
    let ten = new BigNumber(inputs.hex, 16);
    var value = ten.toString(10);
    return value;
  }

  async function addaddress() {
    try {
      setclaimprogress("true");
      let arr = [];
      let datas = await getCurAddr();
      if (
        datas &&
        datas.address &&
        datas.address != undefined &&
        datas.address != null &&
        datas.address != "" &&
        localStorage.getItem("account")
      ) {
        let web3 = new Web3(
          datas &&
          datas.provider &&
          datas.provider != null &&
          datas.provider != undefined &&
          datas.provider != ""
            ? datas.provider
            : window.ethereum
        );
        var bonjourContract = new web3.eth.Contract(
          bonjourapi,
          config.bonjourcontract
        );
        let owneraddress = await bonjourContract.methods.owner().call();
        if (owneraddress.toLowerCase() == datas.address.toLowerCase()) {
          if (
            claimaddress &&
            claimaddress != null &&
            claimaddress != undefined &&
            claimaddress != ""
          ) {
            if (web3.utils.isAddress(claimaddress)) {
              let userdetails = await bonjourContract.methods
                .users(claimaddress)
                .call();
              let _status = await bonjourContract.methods
                .claimBlockAddress(claimaddress)
                .call();
              if (userdetails.isExist) {
                if (_status == false) {
                  arr.push(claimaddress);
                  await bonjourContract.methods.addclaimBlockAddress(arr).send({
                    from: datas.address,
                  });
                  let rect = {
                    address: claimaddress,
                  };
                  let _records = await add_Address(rect);
                  if (_records && _records.data && _records.data.message) {
                    setclaimprogress("false");
                    toastAlert("success", _records.data.message, "network");
                    setclaimaddress("");
                    getclaimlist();
                  }
                } else {
                  setclaimprogress("false");
                  toastAlert(
                    "success",
                    "Already Wallet Added to list",
                    "network"
                  );
                }
              } else {
                setclaimprogress("false");
                toastAlert(
                  "success",
                  "User wallet not joined under our Bonjour",
                  "network"
                );
              }
            } else {
              setclaimprogress("false");
              toastAlert("error", "Enter Valid Address", "network");
            }
          } else {
            setclaimprogress("false");
            toastAlert("error", "Enter Valid Address", "network");
          }
        } else {
          setclaimprogress("false");
          toastAlert("error", "Login into Admin Address", "network");
        }
      } else {
        setclaimprogress("false");
        toastAlert("error", "Please select BSC chain network", "network");
      }
    } catch (err) {
      console.log(err, "--");
      toastAlert("error", "Process Cancelled", "network");
      setclaimprogress("false");
    }
  }

  async function removeaddress(addr) {
    try {
      if (removeprogress == "false") {
        setremoveprogress("true");
        let datas = await getCurAddr();
        if (
          datas &&
          datas.address &&
          datas.address != undefined &&
          datas.address != null &&
          datas.address != "" &&
          localStorage.getItem("account")
        ) {
          let web3 = new Web3(
            datas &&
            datas.provider &&
            datas.provider != null &&
            datas.provider != undefined &&
            datas.provider != ""
              ? datas.provider
              : window.ethereum
          );
          var bonjourContract = new web3.eth.Contract(
            bonjourapi,
            config.bonjourcontract
          );
          let owneraddress = await bonjourContract.methods.owner().call();
          if (owneraddress.toLowerCase() == datas.address.toLowerCase()) {
            if (web3.utils.isAddress(addr)) {
              let userdetails = await bonjourContract.methods
                .users(addr)
                .call();
              let _status = await bonjourContract.methods
                .claimBlockAddress(addr)
                .call();
              if (userdetails.isExist) {
                if (_status == true) {
                  await bonjourContract.methods
                    .removeclaimBlockAddress(addr)
                    .send({
                      from: datas.address,
                    });
                  let rect = {
                    address: addr,
                  };
                  let _records = await remove_Address(rect);
                  if (_records && _records.data && _records.data.message) {
                    setremoveprogress("false");
                    toastAlert("success", _records.data.message, "network");
                    getclaimlist();
                  }
                } else {
                  setremoveprogress("false");
                  toastAlert(
                    "success",
                    "Wallet does not exist in list",
                    "network"
                  );
                }
              } else {
                setremoveprogress("false");
                toastAlert(
                  "success",
                  "User wallet not joined under our Bonjour",
                  "network"
                );
              }
            } else {
              setremoveprogress("false");
              toastAlert("error", "Invalid Address", "network");
            }
          } else {
            setremoveprogress("false");
            toastAlert("error", "Login into Admin Address", "network");
          }
        } else {
          setremoveprogress("false");
          toastAlert("error", "Please select BSC chain network", "network");
        }
      } else {
        toastAlert(
          "error",
          "Please Wait Already processing a request",
          "network"
        );
      }
    } catch (err) {
      toastAlert("error", "Process Cancelled", "network");
      setremoveprogress("false");
    }
  }

  async function distributeglobal() {
    try {
      setprogress("true");
      let datas = await getCurAddr();
      if (
        datas &&
        datas.address &&
        datas.address != undefined &&
        datas.address != null &&
        datas.address != "" &&
        localStorage.getItem("account")
      ) {
        let web3 = new Web3(
          datas &&
          datas.provider &&
          datas.provider != null &&
          datas.provider != undefined &&
          datas.provider != ""
            ? datas.provider
            : window.ethereum
        );
        var bonjourContract = new web3.eth.Contract(
          bonjourapi,
          config.bonjourcontract
        );
        let owneraddress = await bonjourContract.methods.owner().call();
        if (owneraddress.toLowerCase() == datas.address.toLowerCase()) {
          if (globalpoolamount && parseFloat(globalpoolamount) > 0) {
            let globamt = parseFloat(globalshare) * 1e18;
            if (globamt && parseFloat(globamt) > 0) {
              if (parseFloat(globamt) <= parseFloat(globalpoolamount)) {
                let amt = await convert(globamt);
                let register = await bonjourContract.methods
                  .globalPoolDistribution(amt.toString())
                  .send({
                    from: datas.address,
                  });
                if (register && register.status && register.transactionHash) {
                  setprogress("false");
                  toastAlert("success", "Distributed Successfully", "network");
                  window.location.reload();
                } else {
                  setprogress("false");
                  toastAlert("success", "Failed , try again later", "network");
                }
              } else {
                setprogress("false");
                let msg =
                  "Amount must be less than or equal to Global Volume " +
                  (parseFloat(globalpoolamount) / 1e18).toFixed(2);
                toastAlert("error", msg, "network");
              }
            } else {
              setprogress("false");
              toastAlert("success", "Enter Valid Amount", "network");
            }
          } else {
            setprogress("false");
            toastAlert("error", "Insufficient Global Pool Amount", "network");
          }
        } else {
          setprogress("false");
          toastAlert("error", "Login into Admin Address", "network");
        }
      } else {
        setprogress("false");
        toastAlert("error", "Please select BSC chain network", "network");
      }
    } catch (err) {
      toastAlert("error", "Process Cancelled", "network");
      setprogress("false");
    }
  }

  async function safewithdraw() {
    try {
      setwithdrawprogress("true");
      if (withamt && parseFloat(withamt) > 0) {
        if (withaddress) {
          let datas = await getCurAddr();
          if (
            datas &&
            datas.address &&
            datas.address != undefined &&
            datas.address != null &&
            datas.address != "" &&
            localStorage.getItem("account")
          ) {
            let web3 = new Web3(
              datas &&
              datas.provider &&
              datas.provider != null &&
              datas.provider != undefined &&
              datas.provider != ""
                ? datas.provider
                : window.ethereum
            );
            if (web3.utils.isAddress(withaddress)) {
              var bonjourContract = new web3.eth.Contract(
                bonjourapi,
                config.bonjourcontract
              );
              let owneraddress = await bonjourContract.methods.owner().call();
              let amt = parseFloat(withamt) * 1e18;
              amt = await convert(amt);
              if (owneraddress.toLowerCase() == datas.address.toLowerCase()) {
                let register = await bonjourContract.methods
                  .safeWithdraw(amt.toString(), withaddress)
                  .send({
                    from: datas.address,
                  });
                if (register && register.status && register.transactionHash) {
                  setwithdrawprogress("false");
                  toastAlert(
                    "success",
                    "Withdraw Successfully Done",
                    "network"
                  );
                  window.location.reload();
                } else {
                  setwithdrawprogress("false");
                  toastAlert("success", "Failed , try again later", "network");
                }
              } else {
                setwithdrawprogress("false");
                toastAlert("error", "Login into Admin Address", "network");
              }
            } else {
              setwithdrawprogress("false");
              toastAlert("error", "Please Enter Valid Address", "network");
            }
          } else {
            setwithdrawprogress("false");
            toastAlert("error", "Please select BSC chain network", "network");
          }
        } else {
          setwithdrawprogress("false");
          toastAlert("error", "Please Enter Valid Address", "network");
        }
      } else {
        setwithdrawprogress("false");
        toastAlert("error", "Please Enter Valid Amount", "network");
      }
    } catch (err) {
      console.log(err, "===df");
      setwithdrawprogress("false");
      toastAlert("error", "Process Cancelled", "network");
    }
  }

  async function earnlimitupdate() {
    try {
      setearnprogress("true");
      if (earnlimit && parseFloat(earnlimit) > 0) {
        let datas = await getCurAddr();
        if (
          datas &&
          datas.address &&
          datas.address != undefined &&
          datas.address != null &&
          datas.address != "" &&
          localStorage.getItem("account")
        ) {
          let web3 = new Web3(
            datas &&
            datas.provider &&
            datas.provider != null &&
            datas.provider != undefined &&
            datas.provider != ""
              ? datas.provider
              : window.ethereum
          );
          var bonjourContract = new web3.eth.Contract(
            bonjourapi,
            config.bonjourcontract
          );
          let owneraddress = await bonjourContract.methods.owner().call();
          if (owneraddress.toLowerCase() == datas.address.toLowerCase()) {
            let register = await bonjourContract.methods
              .updateearnLimit(earnlimit.toString())
              .send({
                from: datas.address,
              });
            if (register && register.status && register.transactionHash) {
              setearnprogress("false");
              toastAlert("success", "Updated Successfully", "network");
              window.location.reload();
            } else {
              setearnprogress("false");
              toastAlert("success", "Failed , try again later", "network");
            }
          } else {
            setearnprogress("false");
            toastAlert("error", "Login into Admin Address", "network");
          }
        } else {
          setearnprogress("false");
          toastAlert("error", "Please select BSC chain network", "network");
        }
      } else {
        setearnprogress("false");
        toastAlert("error", "Please Enter Valid Limit", "network");
      }
    } catch (err) {
      console.log(err, "===df");
      setearnprogress("false");
      toastAlert("error", "Process Cancelled", "network");
    }
  }

  async function updateminwithdraw() {
    try {
      setminprogress("true");
      if (minval && parseFloat(minval) > 0) {
        let datas = await getCurAddr();
        if (
          datas &&
          datas.address &&
          datas.address != undefined &&
          datas.address != null &&
          datas.address != "" &&
          localStorage.getItem("account")
        ) {
          let web3 = new Web3(
            datas &&
            datas.provider &&
            datas.provider != null &&
            datas.provider != undefined &&
            datas.provider != ""
              ? datas.provider
              : window.ethereum
          );
          var bonjourContract = new web3.eth.Contract(
            bonjourapi,
            config.bonjourcontract
          );
          let owneraddress = await bonjourContract.methods.owner().call();
          if (owneraddress.toLowerCase() == datas.address.toLowerCase()) {
            let register = await bonjourContract.methods
              .setminWithdrawAmount(minval.toString())
              .send({
                from: datas.address,
              });
            if (register && register.status && register.transactionHash) {
              setminprogress("false");
              toastAlert("success", "Updated Successfully", "network");
              window.location.reload();
            } else {
              setminprogress("false");
              toastAlert("success", "Failed , try again later", "network");
            }
          } else {
            setminprogress("false");
            toastAlert("error", "Login into Admin Address", "network");
          }
        } else {
          setminprogress("false");
          toastAlert("error", "Please select BSC chain network", "network");
        }
      } else {
        setminprogress("false");
        toastAlert("error", "Please Enter Valid Value", "network");
      }
    } catch (err) {
      setminprogress("false");
      toastAlert("error", "Process Cancelled", "network");
    }
  }

  async function fetchrecord(start_date, end_date, _event) {
    try {
      let data = {
        startdate: start_date != "" ? start_date : today,
        enddate: end_date != "" ? end_date : nextday,
        event: _event,
      };
      let rec = await filter_event(data);
      if (rec && rec.data && rec.data.data) {
        setfilterrec(rec.data.data);
        let record = [];
        for (let i in rec.data.data) {
          var obj_rec = {
            SNO: parseInt(i) + 1,
            UserId: rec.data.data[i].userid,
            Address: rec.data.data[i].address,
            Activity:
              rec.data.data[i].event == "joined"
                ? "Join"
                : rec.data.data[i].event == "reinvest"
                ? "Re-Invest"
                : rec.data.data[i].event == "retopup"
                ? "Re-Topup"
                : rec.data.data[i].event == "globalclaim"
                ? "Claim"
                : "Claim",
            Amount:
              parseInt(rec.data.data[i].amount).toString().length > 8
                ? (parseFloat(rec.data.data[i].amount) / 1e18).toFixed(2)
                : parseFloat(rec.data.data[i].amount).toFixed(2),
            Date: moment(rec.data.data[i].createdAt).format(
              "MMM Do YYYY, h:mm:ss A"
            ),
          };
          record.push(obj_rec);
          if (parseInt(i) + 1 == rec.data.data.length) {
            settabledata(record);
          }
        }
      } else {
        setfilterrec([]);
      }
    } catch (err) {
      console.log(err, "====s=ss=");
    }
  }

  const onKeyUp = (e) => {
    console.log(e.target.value, "e.target.value");
    var numbers = /^[0-9]+$/;
    if (!e.target.value.match(numbers)) {
      toast.warn("Please enter valid");
      console.log(e.target.value, "e.target.value");
      return false;
    }
  };

  const validPositive = (e) => {
    console.log(
      new RegExp(`^\\d*(\\.\\d{0,8})?$`).test(e.target.value),
      "valllluee"
    );
    if (
      new RegExp(`^\\d*(\\.\\d{0,8})?$`).test(e.target.value) ||
      (e.target.value = "")
    ) {
      e.preventDefault();
    }
  };

  return (
    <div>
      <Navbar
        handleDrawerOpen={handleDrawerOpen}
        open={open}
        handleMenu={handleMenu}
        handleClose={handleClose}
        anchorEl={anchorEl}
      />
      <ScrollToTopOnMount />

      <div className="pagecolor">
        <section className="settings">
          <div className="container">
            <h2 className="inner_title mb-4">Update Lottery Settings</h2>
            <div className="dashboard_panel">
              <div className="row">
                <div className="col-lg-6 primary_box dash_box mt-4 dflx-none">
                  <label for="exampleFormControlInput1" class="form-label">
                    OPERATOR
                  </label>
                  <input
                    type="text"
                    class="form-control primary_inp mb-2"
                    id="operator"
                    value={operator}
                    placeholder="Enter Address"
                    onChange={onChange}
                    style={{ color: "white" }}
                    // onKeyDown={(evt) =>
                    //   [
                    //     "E",
                    //     "+",
                    //     "-",
                    //     ".",
                    //     "a",
                    //     "b",
                    //     "c",
                    //     "d",
                    //     "e",
                    //     "f",
                    //     "g",
                    //     "h",
                    //     "i",
                    //     "j",
                    //     "k",
                    //     "l",
                    //     "m",
                    //     "n",
                    //     "o",
                    //     "p",
                    //     "q",
                    //     "r",
                    //     "s",
                    //     "t",
                    //     ,"u","v","w","x","y","z","@","$","!","#","%","^","*","(",")","_","=","|","?",",",";",":"
                    //   ].includes(evt.key) && evt.preventDefault()
                    // }
                  />
                  {validateError.operator && (
                    <span className="text-danger">
                      {validateError.operator}
                    </span>
                  )}
                  <label for="exampleFormControlInput1" class="form-label">
                    TREASURY
                  </label>
                  <input
                    type="text"
                    class="form-control primary_inp mb-2"
                    id="treasury"
                    value={treasury}
                    placeholder="Enter Address"
                    onChange={onChange}
                    style={{ color: "white" }}
                  />
                  {validateError.treasury && (
                    <span className="text-danger">
                      {validateError.treasury}
                    </span>
                  )}
                  <label for="exampleFormControlInput1" class="form-label">
                    INJECTOR
                  </label>
                  <input
                    type="text"
                    class="form-control primary_inp mb-2"
                    id="injector"
                    value={injector}
                    placeholder="Enter Address"
                    onChange={onChange}
                    style={{ color: "white" }}
                  />
                  {validateError.injector && (
                    <span className="text-danger">
                      {validateError.injector}
                    </span>
                  )}
                  <br />
                  <button
                    className="primary_btn"
                    type="button"
                    onClick={handleFormSubmit}
                  >
                    {submitButton && (
                      <i class="fas fa-spinner fa-spin mr-2"></i>
                    )}
                    Add
                  </button>
                  {submitButton && (
                    <lable>Don't refresh when page loading...</lable>
                  )}
                </div>
              </div>
            </div>
            <br />
            <br />
            <h2 className="inner_title mb-4">Update Price</h2>
            <div className="dashboard_panel">
              <div className="row">
                <div className="col-lg-6 primary_box dash_box mt-4 dflx-none">
                  <label for="exampleFormControlInput1" class="form-label">
                    Ticket price
                  </label>
                  <input
                    type="text"
                    class="form-control primary_inp mb-2"
                    id="ticketprize"
                    value={ticketprize}
                    placeholder="Enter price"
                    onChange={(e) => setticketprize(e.target.value)}
                    style={{ color: "white" }}
                  />
                  <label for="exampleFormControlInput1" class="form-label">
                    AED price
                  </label>
                  <input
                    type="text"
                    class="form-control primary_inp mb-2"
                    id="AEDprize"
                    value={AEDprize}
                    placeholder="Enter price"
                    onChange={(e) => setAEDprize(e.target.value)}
                    style={{ color: "white" }}
                  />

                  <br />
                  <button
                    className="primary_btn"
                    type="button"
                    onClick={handleFormSubmit2}
                  >
                    {submitButton && (
                      <i class="fas fa-spinner fa-spin mr-2"></i>
                    )}
                    Add
                  </button>
                  {submitButton && (
                    <lable>Don't refresh when page loading...</lable>
                  )}
                </div>
              </div>
            </div>
            <br /> <br />
            <br />
            <br />
            <div>
              <h2 className="inner_title">Start Lottery</h2>
              <div className="row settings_row">
                <div className="col-md-12">
                  <div className="primary_box dash_box mt-4">
                    <div className="form-group row">
                      <div className="col-lg-12 m-auto text-start">
                        <div className="row">
                          <div className="col-md-6">
                            <label
                              for="exampleFormControlInput1"
                              class="form-label"
                            >
                              End Time
                            </label>
                            {/* <input
                              type="text"
                              class="form-control primary_inp"
                              placeholder="End time in timestamp"
                              onChange={onChange1}
                              id="endTime"
                              value={endTime}
                            /> */}

                            <input
                              type="datetime-local"
                              class="form-control primary_inp"
                              onChange={onchange2}
                              id="endTime"
                              name="time"
                              // value={endTime}
                              style={{ maxWidth: "220px" }}
                            />
                            {validateError.endTime && (
                              <span className="text-danger">
                                {validateError.endTime}
                              </span>
                            )}
                          </div>
                          <div className="col-md-6">
                            <label
                              for="exampleFormControlInput1"
                              class="form-label"
                            >
                              Ticket Price
                            </label>
                            <input
                              type="text"
                              class="form-control primary_inp"
                              id="ticketPice"
                              // onKeyUp={onKeyUp}
                              placeholder="Ticket Price"
                              value={ticketPice}
                              onChange={onChange1}
                              onInput={validPositive}
                            />
                            {validateError.ticketPice && (
                              <span className="text-danger">
                                {validateError.ticketPice}
                              </span>
                            )}
                          </div>
                          <div className="col-md-6">
                            <label
                              for="exampleFormControlInput1"
                              class="form-label"
                            >
                              First Prize %
                            </label>
                            <input
                              type="text"
                              class="form-control primary_inp"
                              id="rew1"
                              // onKeyUp={onKeyUp}
                              onInput={validPositive}
                              placeholder=" First Prize %"
                              value={rew1}
                              onChange={onChange1}
                            />
                            {validateError.rew1 && (
                              <span className="text-danger">
                                {validateError.rew1}
                              </span>
                            )}
                          </div>
                          <div className="col-md-6">
                            <label
                              for="exampleFormControlInput1"
                              class="form-label"
                            >
                              Second Prize %
                            </label>
                            <input
                              type="text"
                              class="form-control primary_inp"
                              // onKeyUp={onKeyUp}
                              onInput={validPositive}
                              id="rew2"
                              placeholder="Second Prize %"
                              value={rew2}
                              onChange={onChange1}
                            />
                            {validateError.rew2 && (
                              <span className="text-danger">
                                {validateError.rew2}
                              </span>
                            )}
                          </div>
                          <div className="col-md-6">
                            <label
                              for="exampleFormControlInput1"
                              class="form-label"
                            >
                              Third Prize %
                            </label>
                            <input
                              type="text"
                              class="form-control primary_inp"
                              onInput={validPositive}
                              id="rew3"
                              value={rew3}
                              placeholder="Third Prize %"
                              onChange={onChange1}
                            />
                            {validateError.rew3 && (
                              <span className="text-danger">
                                {validateError.rew3}
                              </span>
                            )}
                          </div>
                          <div className="col-md-6">
                            <label
                              for="exampleFormControlInput1"
                              class="form-label"
                            >
                              Fourth Prize %
                            </label>
                            <input
                              type="text"
                              class="form-control primary_inp"
                              onInput={validPositive}
                              id="rew4"
                              placeholder="Fourth Prize %"
                              value={rew4}
                              onChange={onChange1}
                            />
                            {validateError.rew4 && (
                              <span className="text-danger">
                                {validateError.rew4}
                              </span>
                            )}
                          </div>
                          <div className="col-md-6">
                            <label
                              for="exampleFormControlInput1"
                              class="form-label"
                            >
                              Fifth Prize %
                            </label>
                            <input
                              type="text"
                              class="form-control primary_inp"
                              id="rew5"
                              placeholder="Fifth Prize %"
                              onInput={validPositive}
                              value={rew5}
                              onChange={onChange1}
                            />
                            {validateError.rew5 && (
                              <span className="text-danger">
                                {validateError.rew5}
                              </span>
                            )}
                          </div>
                          <div className="col-md-6">
                            <label
                              for="exampleFormControlInput1"
                              class="form-label"
                            >
                              Sixth Prize %
                            </label>
                            <input
                              type="text"
                              class="form-control primary_inp"
                              id="rew6"
                              onInput={validPositive}
                              placeholder="Sixth Prize %"
                              value={rew6}
                              onChange={onChange1}
                            />
                            {validateError.rew6 && (
                              <span className="text-danger">
                                {validateError.rew6}
                              </span>
                            )}
                          </div>
                        </div>
                        <button
                          className="primary_btn"
                          type="button"
                          onClick={handleFormSubmit1}
                        >
                          {submitButton && (
                            <i class="fas fa-spinner fa-spin mr-2"></i>
                          )}
                          Add
                        </button>
                        {submitButton && (
                          <lable>Don't refresh when page loading...</lable>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div></div>
          </div>
        </section>
      </div>
      <div>
        <Lottarylist></Lottarylist>
      </div>
      <div className="inner_wrapper">
        <div className="container">
          <div className="dashboard_panel join_now_panel">
            <div className="row">
              <div className="col-md-8 m-auto">
                <div className="title_flex">
                  <h2 className="inner_title">Setting</h2>
                  <div className="row settings_row">
                    <div className="col-md-6">
                      <div className="primary_box dash_box mt-4">
                        <h2>Safe Withdraw</h2>
                        <div className="form-group row">
                          <div className="col-lg-12 m-auto text-start">
                            <label
                              for="exampleFormControlInput1"
                              class="form-label"
                            >
                              Withdraw Amount
                            </label>
                            <input
                              type="Number"
                              class="form-control primary_inp"
                              id="exampleFormControlInput1"
                              placeholder="Enter Amount"
                              value={withamt}
                              onChange={withamtchange}
                              min="0"
                            />
                            <label
                              for="exampleFormControlInput1"
                              class="form-label"
                            >
                              Withdraw Address
                            </label>
                            <input
                              type="text"
                              class="form-control primary_inp"
                              id="exampleFormControlInput1"
                              placeholder="Enter Address"
                              value={withaddress}
                              onChange={withaddrchange}
                            />
                            <button
                              className="primary_btn"
                              type="button"
                              onClick={
                                withdrawprogress == "false"
                                  ? () => safewithdraw()
                                  : () => empty()
                              }
                            >
                              {withdrawprogress == "false"
                                ? "Withdraw"
                                : "Processing..Please Wait.."}
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>

                    {/* <div className="col-md-6">
                      <div className="primary_box dash_box mt-4">
                       <h2>Earn Limit</h2>
                        <div className="form-group row">
                          <div className="col-lg-12 m-auto text-start">
                            <label for="exampleFormControlInput1" class="form-label">Earn Limit (%) </label>
                            <input type="Number" class="form-control primary_inp" id="exampleFormControlInput1" placeholder="Enter Earn Limit" value={earnlimit} onChange={earnlimitchange} />
                           <button className="primary_btn" type="button" onClick={earnprogress=="false"?()=>earnlimitupdate():()=>empty()}>{earnprogress=="false"?"Update":"Processing..Please Wait.."}</button>
                          </div>
                        </div>                    
                      </div>
                    </div> */}

                    <div className="col-md-6">
                      <div className="primary_box dash_box mt-4">
                        <h2>Update Minimum Withdraw</h2>
                        <div className="form-group row">
                          <div className="col-lg-12 m-auto text-start">
                            <label
                              for="exampleFormControlInput1"
                              class="form-label"
                            >
                              Minimum Withdraw
                            </label>
                            <input
                              type="Number"
                              class="form-control primary_inp"
                              id="exampleFormControlInput1"
                              placeholder="Enter Minimum Value"
                              value={minval}
                              onChange={minvalchange}
                            />
                            <button
                              className="primary_btn"
                              type="button"
                              onClick={
                                minprogress == "false"
                                  ? () => updateminwithdraw()
                                  : () => empty()
                              }
                            >
                              {minprogress == "false"
                                ? "Update"
                                : "Processing..Please Wait.."}
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>

                    {/* <div className="col-md-6">
                      <div className="primary_box dash_box mt-4">
                       <h2>Maximum Withdraw</h2>
                        <div className="form-group row">
                          <div className="col-lg-12 m-auto text-start">
                            <label for="exampleFormControlInput1" class="form-label">Maximum Withdraw</label>
                            <input type="Number" class="form-control primary_inp" id="exampleFormControlInput1" placeholder="Enter Maximum Value" />
                           <button className="primary_btn" type="button" onClick={progress=="false"?()=>distributeglobal():()=>empty()}>{progress=="false"?"Update":"Processing..Please Wait.."}</button>
                          </div>
                        </div>                    
                      </div> 
                    </div>*/}
                  </div>
                </div>

                <div className="row settings_row">
                  <div class="col-lg-12">
                    <div className="primary_box dash_box">
                      <div className="">
                        <h2>
                          {"Global Pool Distribution ( Global Volume : " +
                            (parseFloat(globalpoolamount) / 1e18).toFixed(2) +
                            " )"}
                        </h2>
                        <div className="col-lg-6 m-auto text-start">
                          <label
                            for="exampleFormControlInput1"
                            class="form-label"
                          >
                            {"Global Pool Amount"}
                          </label>
                          <input
                            type="text"
                            class="form-control primary_inp"
                            id="exampleFormControlInput1"
                            placeholder="Enter Amount"
                            value={globalshare}
                            onChange={globalsharechange}
                          />
                          <button
                            className="primary_btn"
                            type="button"
                            onClick={
                              progress == "false"
                                ? () => distributeglobal()
                                : () => empty()
                            }
                          >
                            {progress == "false"
                              ? "Distribute"
                              : "Processing..Please Wait.."}
                          </button>
                        </div>
                        <div className="plan_income_table_div">
                          <div className="table-responsive">
                            <table className="table plan_income_table mt-4 w-100">
                              <thead>
                                <tr>
                                  <th>S.No</th>
                                  <th>Address</th>
                                </tr>
                              </thead>
                              <tbody>
                                {records &&
                                  records.length > 0 &&
                                  records.map((item, index) => {
                                    return (
                                      <tr>
                                        <td>{parseInt(index) + 1}</td>
                                        <td>{item}</td>
                                      </tr>
                                    );
                                  })}
                                {records && records.length <= 0 && (
                                  <tr>
                                    <td colSpan={2}>
                                      <p className="text-center mt-2">
                                        No Records Found
                                      </p>
                                    </td>
                                  </tr>
                                )}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="row settings_row">
                  <div class="col-lg-12">
                    <div className="primary_box dash_box">
                      <div className="">
                        <h2>Claim block address</h2>
                        <div className="col-lg-6 m-auto text-start">
                          <label
                            for="exampleFormControlInput1"
                            class="form-label"
                          >
                            Wallet Address
                          </label>
                          <input
                            type="text"
                            class="form-control primary_inp"
                            id="exampleFormControlInput1"
                            placeholder="Enter Address"
                            value={claimaddress}
                            onChange={claimaddresschange}
                          />
                          <button
                            className="primary_btn"
                            type="button"
                            onClick={
                              claimprogress == "false"
                                ? () => addaddress()
                                : () => empty()
                            }
                          >
                            {claimprogress == "false"
                              ? "Add"
                              : "Processing..Please Wait.."}
                          </button>
                        </div>
                        <div className="plan_income_table_div">
                          <div className="table-responsive">
                            {removeprogress == "true" && (
                              <p className="text-center mt-2">
                                Processing ... Please Wait....
                              </p>
                            )}
                            <table className="table plan_income_table mt-4 w-100">
                              <thead>
                                <tr>
                                  <th>S.No</th>
                                  <th>Address</th>
                                  <th>Action</th>
                                </tr>
                              </thead>
                              <tbody>
                                {claimlist &&
                                  claimlist.length > 0 &&
                                  claimlist.map((item, index) => {
                                    return (
                                      <tr>
                                        <td>{parseInt(index) + 1}</td>
                                        <td>{item.address}</td>
                                        <td>
                                          <button
                                            className="primary_btn"
                                            type="button"
                                            onClick={() =>
                                              removeaddress(item.address)
                                            }
                                            disabled={
                                              removeprogress == "true"
                                                ? "true"
                                                : ""
                                            }
                                          >
                                            Remove
                                          </button>
                                        </td>
                                      </tr>
                                    );
                                  })}
                                {claimlist && claimlist.length <= 0 && (
                                  <tr>
                                    <td colSpan={3}>
                                      <p className="text-center mt-2">
                                        No Records Found
                                      </p>
                                    </td>
                                  </tr>
                                )}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="row settings_row">
                  <div className="col-lg-12">
                    <div className="primary_box dash_box">
                      <div className="">
                        <h2>Fetch Records</h2>
                        <div className="row">
                          <div className="col-lg-6 text-start">
                            <label
                              for="exampleFormControlInput1"
                              class="form-label"
                            >
                              Start Date
                            </label>
                            <input
                              type="datetime-local"
                              class="form-control primary_inp"
                              id="startdate"
                              onChange={startdatechange}
                              max={
                                enddate && enddate != "" ? enddate : currentdate
                              }
                            />
                          </div>
                          <div className="col-lg-6 text-start">
                            <label
                              for="exampleFormControlInput1"
                              class="form-label"
                            >
                              End Date
                            </label>
                            <input
                              type="datetime-local"
                              class="form-control primary_inp"
                              id="enddate"
                              onChange={enddatechange}
                              min={startdate}
                              max={currentdate}
                            />
                          </div>
                          {/* <div className="col-lg-6 text-start">
                            <label for="exampleFormControlInput1" class="form-label">{"Global Pool Amount"}</label>
                            <input type="file" class="form-control primary_inp" id="exampleFormControlInput1" />
                          </div>
                          <div className="col-lg-6 text-start">
                            <label for="exampleFormControlInput1" class="form-label">{"Global Pool Amount"}</label>
                            <input type="text" class="form-control primary_inp" id="exampleFormControlInput1" placeholder="Enter Amount" value={globalshare}  onChange={globalsharechange}/>
                          </div> */}
                          <div className="col-lg-6 text-start">
                            <label
                              for="exampleFormControlInput1"
                              class="form-label"
                            >
                              Events
                            </label>
                            <select
                              class="form-control primary_inp"
                              onChange={selectchange}
                            >
                              <option value="all" selected>
                                All
                              </option>
                              <option value="joined">Joined</option>
                              <option value="reinvest">Re-Invest</option>
                              {/*<option value="retopup" >Re-Topup</option>*/}
                              <option value="claim">Claim</option>
                              <option value="globalclaim">Global Claim</option>
                            </select>
                          </div>
                        </div>
                        <div className="text-end">
                          {/*<button className="primary_btn" type="button" onClick={()=>saveuserAsExcel()}>Download User Details</button>*/}
                          <button
                            className="primary_btn"
                            type="button"
                            onClick={() => saveAsExcel()}
                          >
                            Download History
                          </button>
                        </div>
                        <div className="plan_income_table_div">
                          <div className="table-responsive">
                            <table
                              className="table plan_income_table mt-4 w-100"
                              id="filtertable"
                            >
                              <thead>
                                <tr>
                                  <th>S.No</th>
                                  <th>User Id</th>
                                  <th>Address</th>
                                  <th>Activity</th>
                                  <th>Amount</th>
                                  <th>Date</th>
                                </tr>
                              </thead>
                              <tbody>
                                {filterrec &&
                                  filterrec.length > 0 &&
                                  filterrec.map((item, index) => {
                                    return (
                                      <tr>
                                        <td>{parseInt(index) + 1}</td>
                                        <td>{item.userid}</td>
                                        <td>{item.address}</td>
                                        <td>
                                          {item.event == "joined"
                                            ? "Join"
                                            : item.event == "reinvest"
                                            ? "Re-Invest"
                                            : item.event == "retopup"
                                            ? "Re-Topup"
                                            : item.event == "globalclaim"
                                            ? "Claim"
                                            : "Claim"}
                                        </td>
                                        <td>
                                          {parseInt(item.amount).toString()
                                            .length > 8
                                            ? (
                                                parseFloat(item.amount) / 1e18
                                              ).toFixed(2)
                                            : parseFloat(item.amount).toFixed(
                                                2
                                              )}
                                        </td>
                                        <td>
                                          {moment(item.createdAt).format(
                                            "MMM Do YYYY, h:mm:ss A"
                                          )}
                                        </td>
                                      </tr>
                                    );
                                  })}
                                {filterrec && filterrec.length <= 0 && (
                                  <tr>
                                    <td colSpan={6}>
                                      <p className="text-center mt-2">
                                        No Records Found
                                      </p>
                                    </td>
                                  </tr>
                                )}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
