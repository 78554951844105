import React, { useState, useEffect, useRef } from "react";
import { useParams } from "react-router-dom";
import Footer from "../components/Footer.js";
import Navbar from "../components/Navbar.js";
import CountUp from "react-countup";
import ScrollAnimation from "react-animate-on-scroll";
import { Scrollbars } from "react-custom-scrollbars";
import Web3 from "web3";
import {
  getCurAddr,
  updatereferral,
  gettotaluser,
  getlasttransaction,
  checkview,
  get_view_count,
} from "../action/Apicontroller";
import { toastAlert } from "../helper/toastAlert";
import config from "../config/config";
import * as $ from "jquery";
import * as bootstrap from "bootstrap";
import axios from "axios";
import Countdown, { zeroPad } from "react-countdown";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";

//import bootstrap from "bootstrap";

import { COLORS, PARTICLE_COUNT } from "./canvas/config";
//import { Particle } from "./canvas/particle";
// import { ShapeMap } from "./canvas/shapemap";
import { Vector2D } from "./canvas/vector";
//import 'bootstrap/dist/css/bootstrap.css';
//import { Modal } from 'bootstrap';

// import DarkHeroStyles from "./canvas/index";
// import tsParticles from "react-tsparticles";
// import Particles from "react-tsparticles";
// import { useCallback } from "react";
// import { loadFull } from "tsparticles";
// import { tsParticles } from "tsparticles-engine";

// Scroll to Top
function ScrollToTopOnMount() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return null;
}

document.addEventListener("DOMContentLoaded", function (event) {
  setTimeout(function () {
    console.log("**");
    var myModalEl = document.getElementById("autopool_info");
    if (myModalEl && myModalEl != null && myModalEl != undefined) {
      var myModal = new bootstrap.Modal(myModalEl, { keyboard: false });
      //myModal.show();
    }
  }, 1000);
});

// type Force = (pos: Vector2D) => Vector2D;

// function getForces(center: Vector2D, minDim: number): Force[] {
//   const factor = 1; //950 / minDim;

//   return [
//     (p) => {
//       const dir = p.sub(center);
//       const mag = dir.mag();
//       dir.mult_mut(7000 / factor / mag / mag);
//       return dir;
//     },
//     (p) => {
//       const dir = center.sub(p);
//       dir.mult_mut(20 / factor / dir.mag());
//       return dir;
//     },
//     (p) => {
//       const dir = center.sub(p);
//       const mag = dir.mag();
//       return new Vector2D(
//         ((1000 / factor) * dir.y) / mag / mag,
//         ((1000 / factor) * -dir.x) / mag / mag
//       );
//     },
//   ];
// }

export default function Home(props) {
  var { id } = useParams();
  // Countdown Timer
  const currentDate = new Date();
  const year =
    currentDate.getMonth() === 11 && currentDate.getDate() > 23
      ? currentDate.getFullYear() + 1
      : currentDate.getFullYear();

  const renderer = ({ days, hours, minutes, seconds }) => {
    return (
      <div className="timer_panel">
        <span>
          <span className="timer_time">{zeroPad(days)}</span>
          <span className="timer_label">Days</span>
        </span>
        <span className="timer_dots">:</span>
        <span>
          <span className="timer_time">{zeroPad(hours)}</span>
          <span className="timer_label">Hrs</span>
        </span>
        <span className="timer_dots">:</span>
        <span>
          <span className="timer_time">{zeroPad(minutes)}</span>
          <span className="timer_label">Mins</span>
        </span>
        <span className="timer_dots">:</span>
        <span>
          <span className="timer_time">{zeroPad(seconds)}</span>
          <span className="timer_label">Secs</span>
        </span>
      </div>
    );
  };

  const [data, setdata] = useState({});
  const [referaladdress, setreferaladdress] = useState("");
  const [latestrec, setlatestrec] = useState([]);
  const [viewcounts, setviewcounts] = useState(0);
  const [totaluser, settotaluser] = useState(0);
  const [payoutamt, setpayoutamt] = useState(0);

  var recent_activity_settings = {
    centerMode: true,
    centerPadding: "1px",
    dots: false,
    infinite: true,
    speed: 300,
    slidesToShow: 3,
    responsive: [
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 540,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  // Animation Ends
  useEffect(() => {
    getdetails();
  }, []);

  let idy = 0;

  async function checkreferal(_data) {
    console.log(id, "-====inhome", _data);
    if (id && id != null && id != undefined && id != "" && idy == 0) {
      if (id.toLowerCase() != _data.address.toLowerCase()) {
        idy = 1;
        //let web3 =  new Web3(_data && _data.provider && _data.provider!=null && _data.provider!=undefined && _data.provider!=""?_data.provider:window.ethereum);
        //if(web3.utils.isAddress(id)){
        // var bonjourContract = new web3.eth.Contract(bonjourapi, config.bonjourcontract);
        // let userdetails = await bonjourContract.methods.users(id).call();
        // if(userdetails.isExist){
        //   let minedet = await bonjourContract.methods.users(_data.address).call();
        //   if(!minedet.isExist){
        console.log("hiiiiii");
        setreferaladdress(id);
        let inputdata = {
          useraddress: _data.address,
          referral: id,
        };
        console.log("iiiiih");
        await updatereferral(inputdata);
        // }
        // }
        //}
      }
    }
  }

  // async function getviewcount() {
  //   let rect = await get_view_count();
  //   if(rect && rect.data && rect.data.data){
  //     setviewcounts((rect.data.data).length);
  //   }
  // }

  async function getdetails() {
    let rects = await gettotaluser();
    if (rects && rects.data && rects.data.data) {
      settotaluser(rects.data.data.count);
    }
    if (rects && rects.data && rects.data.data) {
      setpayoutamt(rects.data.data.payout);
    }

    let rect = await getlasttransaction();
    if (rect && rect.data && rect.data.data) {
      console.log(rect.data.data, "====rect.data.data");
      setlatestrec(rect.data.data);
    }
    let datas = await getCurAddr();
    if (
      datas &&
      datas.address &&
      datas.address != undefined &&
      datas.address != null &&
      datas.address != "" &&
      localStorage.getItem("account")
    ) {
      setdata(datas);
      if (datas.userdetails && datas.userdetails.isExist) {
        //window.location.href="/dashboard"
      } else {
        await checkreferal(datas);
      }
    }
  }

  // async function getIpdetails(){
  //   const res = await axios.get("https://api.ipify.org/?format=json");
  //   if(res && res.data && res.data.ip){
  //     let input = {
  //       "address" : res.data.ip
  //     }
  //     checkview(input);
  //   }
  // }

  return (
    <div>
      <ScrollToTopOnMount />
      <Navbar />
      <section className="out_header">
        <div className="page_header">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-lg-6 order-lg-1 order-2">
                <div className="head_title">
                  <h1>
                    The Highly anticipated <span>decentralized</span>{" "}
                    <span>matrix platform</span>
                  </h1>
                  <h5>
                    Maximize your earnings by investing a minimum amount.
                    Explore new opportunities with Bonjour.
                  </h5>
                </div>
                <div className="infobox">
                  <center>
                    <div className="row">
                      <div className="col-md-6">
                        <div className="box">
                          <img
                            src={require("../assets/images/b2.png")}
                            className="img-fluid"
                            alt="Banner"
                          />
                          <h5>
                            {(
                              parseFloat(590000) + parseFloat(payoutamt)
                            ).toFixed(2)}{" "}
                            BUSD
                          </h5>
                          <p>Total Pay Out</p>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="box box_purple">
                          <img
                            src={require("../assets/images/b3.png")}
                            className="img-fluid"
                            alt="Banner"
                          />
                          <h5>{totaluser ? totaluser : 0}</h5>
                          <p>Total Participants</p>
                        </div>
                      </div>
                    </div>
                  </center>
                </div>
              </div>
              <div className="col-lg-6 order-lg-2 order-1">
                <div className="banner_img">
                  <img
                    src={require("../assets/images/banner_gif.gif")}
                    className="img-fluid banner_mobile"
                    alt="Icon"
                  />
                  <div className="globe">
                    <div className="sphere"></div>
                    <div className="outer_shadow"></div>
                    <div className="worldmap">
                      <div className="worldmap_back"></div>
                      <div className="worldmap_front"></div>
                    </div>
                    <div className="inner_shadow"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div className="main_wrapper">
        {/* Community Section */}
        <section className="features_section">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <ScrollAnimation animateIn="slideInUp">
                  <h3 className="main_title_small text-center">
                    Global community
                  </h3>
                </ScrollAnimation>
              </div>
              <div className="col-lg-12">
                <ScrollAnimation animateIn="slideInUp">
                  <h2 className="main_title text-center">
                    {" "}
                    A worldwide enterprise
                  </h2>
                </ScrollAnimation>
                <div className="row features_section_row">
                  <div className="col-md-6 col-lg-4 d-flex">
                    <ScrollAnimation animateIn="slideInUp">
                      <div className="features_box">
                        <img
                          src={require("../assets/images/icon_01.png")}
                          className="img-fluid"
                          alt="Icon"
                        />
                        <h4>Low-risk investment</h4>
                        <p className="inner_para">
                          Maximize your potential income with minimal
                          investment. Bonjour is a risk-free networking portal
                          that enables you to earn significantly through a
                          global community.
                        </p>
                      </div>
                    </ScrollAnimation>
                  </div>
                  <div className="col-md-6 col-lg-4 d-flex">
                    <ScrollAnimation animateIn="slideInUp">
                      <div className="features_box">
                        <img
                          src={require("../assets/images/icon_02.png")}
                          className="img-fluid"
                          alt="Icon"
                        />
                        <h4>Secure Blockchain</h4>
                        <p className="inner_para">
                          Bonjour operates on the highly secure BSC (Binance
                          Smart Chain) blockchain. It is fully decentralized and
                          offers robust data and investment security.
                        </p>
                      </div>
                    </ScrollAnimation>
                  </div>
                  <div className="col-md-6 col-lg-4 d-flex">
                    <ScrollAnimation animateIn="slideInUp">
                      <div className="features_box">
                        <img
                          src={require("../assets/images/icon_03.png")}
                          className="img-fluid"
                          alt="Icon"
                        />
                        <h4>Unrestricted access</h4>
                        <p className="inner_para">
                          There are no barriers to joining Bonjour. You can
                          easily start earning significantly by simply accessing
                          the platform with a minimum $50 investment.
                        </p>
                      </div>
                    </ScrollAnimation>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <div className="bg_img">
          <section className="mar_top_section">
            <div className="container">
              <div className="row">
                <div className="col-lg-12">
                  <ScrollAnimation animateIn="slideInUp">
                    <p className="inner_para text-center">
                      Bonjour's members can earn significant sums of money
                      through simple referrals. There is no limit to the number
                      of referrals. You can invest in Bonjour from a minimum of
                      $50. <br /> Bonjour offers four types of income streams:
                      referral income, rate of returns, level income, and global
                      pool income, all of which are associated with 7 levels.
                    </p>
                  </ScrollAnimation>
                  {/* <ScrollAnimation animateIn="slideInUp">
                    <div className="counter_panel">
                      <div>
                      <img src={require("../assets/images/icon_04.png")} className='img-fluid' alt='Icon' />
                      <h3><CountUp end={49150} /></h3>
                      <h6>All Participants</h6>
                    </div>
                    <div>
                      <img src={require("../assets/images/icon_05.png")} className='img-fluid' alt='Icon' />
                      <h3><CountUp end={50} /></h3>
                      <h6>Total Earned BUSD</h6>
                    </div>
                    <div>
                      <img src={require("../assets/images/icon_06.png")} className='img-fluid' alt='Icon' />
                      <h3><CountUp end={62250} /></h3>
                      <h6>Total Earned USD</h6>
                    </div>
                    </div>
                  </ScrollAnimation> */}
                </div>
              </div>
            </div>
          </section>

          {/* Plan Income Section */}
          <section className="income_section">
            <div className="container">
              <div className="row">
                <div className="col-lg-12">
                  <ScrollAnimation animateIn="slideInUp">
                    <h3 className="main_title_small text-center">
                      Referral Income
                    </h3>
                  </ScrollAnimation>
                </div>
                <div className="col-lg-12">
                  <ScrollAnimation animateIn="slideInUp">
                    <h2 className="main_title text-center">
                      Explanation of level income
                    </h2>
                  </ScrollAnimation>
                  <ScrollAnimation animateIn="slideInUp">
                    <p className="inner_para text-center">
                      All data is stored on the blockchain and is available for
                      public verification.
                    </p>
                    <p className="inner_para text-center">
                      Contract address:{" "}
                      <a
                        href={
                          config.bscurl + "address/" + config.bonjourcontract
                        }
                        target="_blank"
                      >
                        {config.bonjourcontract}
                      </a>
                    </p>
                  </ScrollAnimation>
                  <div className="row mt-5">
                    <div className="col-md-12 col-lg-6 text-center">
                      <ScrollAnimation animateIn="slideInUp">
                        <h5>Level Income (2.5%)</h5>
                        <div className="plan_income_table_div">
                          <div className="table-responsive">
                            <table className="table plan_income_table">
                              <thead>
                                <tr>
                                  <th>Level</th>
                                  <th>Referral</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr>
                                  <td>1</td>
                                  <td>1%</td>
                                </tr>
                                <tr>
                                  <td>2</td>
                                  <td>0.5%</td>
                                </tr>
                                <tr>
                                  <td>3</td>
                                  <td>0.25%</td>
                                </tr>
                                <tr>
                                  <td>4</td>
                                  <td>0.20%</td>
                                </tr>
                                <tr>
                                  <td>5</td>
                                  <td>0.20%</td>
                                </tr>
                                <tr>
                                  <td>6</td>
                                  <td>0.20%</td>
                                </tr>
                                <tr>
                                  <td>7</td>
                                  <td>0.15%</td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </ScrollAnimation>
                    </div>
                    <div className="col-md-12 col-lg-6">
                      <ScrollAnimation animateIn="slideInUp">
                        <h5>Global Pool</h5>
                        <ul className="global_pool_list">
                          <li>
                            The total investment from the team exceeds{" "}
                            <span>$120000</span>.
                          </li>
                        </ul>

                        <h5>Restriction of level Income</h5>
                        <ul className="global_pool_list">
                          <li>
                            To qualify for level 1 income, the user must have a
                            minimum of 5 direct referrals.
                          </li>
                          <li>
                            To qualify for levels 2, 3, and 4 income, the user
                            must have a minimum of 15 direct referrals.
                          </li>
                          <li>
                            To qualify for levels 5, 6, and 7 income, the user's
                            direct referrals must each have a minimum of 3 child
                            referrals who have each referred at least 15
                            directs.
                          </li>
                        </ul>
                      </ScrollAnimation>
                    </div>
                  </div>
                  <h4 className="text-center">
                    1.5% from Investment added Daily Rate of Return
                  </h4>
                </div>
              </div>
            </div>
          </section>
        </div>

        <section className="platform_activity">
          <div className="container">
            <div className="head">
              <ScrollAnimation animateIn="zoomIn">
                <h4 className="main_title_small text-center">PLATFORM</h4>
                <h2 className="main_title text-center"> Recent Activity</h2>
              </ScrollAnimation>
            </div>

            <div className="recent_activity_slider">
              <Slider {...recent_activity_settings}>
                {latestrec &&
                  latestrec.length > 0 &&
                  latestrec.map((item) => {
                    return (
                      <div>
                        <div className="recent_activity_panel">
                          <div className="slider_img">
                            {item && item.event == "regUser" ? (
                              <img
                                src={require("../assets/images/slider_user.png")}
                                className="img-fluid"
                                alt="img"
                              />
                            ) : (
                              <img
                                src={require("../assets/images/slider_wallet.png")}
                                className="img-fluid"
                                alt="img"
                              />
                            )}
                          </div>
                          <div className="activity_type">
                            {(item.event == "claimOrRetopop" ||
                              item.event == "reInvest") && (
                              <div>
                                <p className="green">+ BNB </p>
                              </div>
                            )}

                            {item && item.event == "regUser" ? (
                              <div>
                                <p className="violet">New user joined</p>
                              </div>
                            ) : item && item.event == "reInvest" ? (
                              <div>
                                <p className="violet">Re-Invest</p>
                              </div>
                            ) : (
                              <div>
                                <p className="violet">Claim</p>
                              </div>
                            )}
                          </div>
                          <div className="activity_id">
                            <div>ID {item.userid}</div>
                          </div>
                          <div className="rightbtn">
                            <p>
                              <span>
                                {" "}
                                <a
                                  href={config.bscurl + "tx/" + item.trx}
                                  target="_blank"
                                >
                                  <i className="bi bi-box-arrow-up-right me-2"></i>
                                </a>
                              </span>
                              <span>
                                {" "}
                                {item && item.hours > 0
                                  ? item.hours
                                  : item.min}{" "}
                                {item && item.hours > 0 ? " Hours" : " Min"}{" "}
                              </span>
                            </p>
                          </div>
                        </div>
                      </div>
                    );
                  })}
              </Slider>
            </div>

            {/* <ScrollAnimation animateIn='zoomIn'> */}
            {/* <Scrollbars style={{ width: "100%", height: 450 }}>
              <div className="activity_scroll">
                {latestrec &&
                  latestrec.length > 0 &&
                  latestrec.map((item) => {
                    return (
                      <div className="activity_box_flx">
                        <div>
                          <div className="flxbox">
                            <div>
                              <img
                                src={require("../assets/images/bonjur_logo.png")}
                                className="img-fluid"
                                alt="img"
                              />
                            </div>
                            <div>
                              {item && item.event == "regUser" ? (
                                <img
                                  src={require("../assets/images/user_prf.png")}
                                  className="img-fluid"
                                  alt="img"
                                />
                              ) : (
                                <img
                                  src={require("../assets/images/wallet.png")}
                                  className="img-fluid"
                                  alt="img"
                                />
                              )}
                            </div>
                            {item && item.event == "regUser" ? (
                              <div>
                                <p className="violet">New user joined</p>
                              </div>
                            ) : item && item.event == "reInvest" ? (
                              <div>
                                <p className="violet">Re-Invest</p>
                              </div>
                            ) : (
                              <div>
                                <p className="violet">Claim</p>
                              </div>
                            )}
                            <div>
                              <div className="btn">ID {item.userid}</div>
                            </div>
                            {(item.event == "claimOrRetopop" ||
                              item.event == "reInvest") && (
                              <div>
                                <p className="green">+ BNB </p>
                              </div>
                            )}
                          </div>
                        </div>
                        <div className="rightbtn">
                          <p>
                            <span>
                              {" "}
                              <a
                                href={config.bscurl + "tx/" + item.trx}
                                target="_blank"
                              >
                                <img
                                  src={require("../assets/images/arrow.png")}
                                  className="img-fluid me-2"
                                  alt="img"
                                />
                              </a>
                            </span>
                            <span>
                              {" "}
                              {item && item.hours > 0
                                ? item.hours
                                : item.min}{" "}
                              {item && item.hours > 0 ? " Hours" : " Min"}{" "}
                            </span>
                          </p>
                        </div>
                      </div>
                    );
                  })}
              </div>
            </Scrollbars> */}
          </div>
        </section>

        {/* How It Works Section */}
        <section className="how_works_section">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <ScrollAnimation animateIn="slideInUp">
                  <h3 className="main_title_small text-center">
                    Partners Payout
                  </h3>
                </ScrollAnimation>
              </div>
              <div className="col-lg-12">
                <ScrollAnimation animateIn="slideInUp">
                  <h2 className="main_title text-center">
                    Explanation of partner income
                  </h2>
                </ScrollAnimation>
                <ScrollAnimation animateIn="slideInUp" delay="300">
                  <p className="inner_para  text-center">
                    The first user or parent will access the platform with an
                    initial amount of <span className="txt_bold">$50</span>
                  </p>
                </ScrollAnimation>
                <ScrollAnimation animateIn="slideInUp" delay="600">
                  <p className="inner_para text-center">
                    Initial amount + referral income (BUSD) = $50 + 7%
                  </p>
                </ScrollAnimation>
                <ScrollAnimation animateIn="slideInUp">
                  <div className="box">
                    <ScrollAnimation animateIn="slideInUp">
                      <div className="text-center">
                        <h3>50 BUSD TO JOIN</h3>
                      </div>
                    </ScrollAnimation>
                    <ScrollAnimation animateIn="slideInUp">
                      <div className="text-center mt-5">
                        <img
                          src={require("../assets/images/tree_structure.png")}
                          className="img-fluid"
                          alt="Icon"
                        />
                      </div>
                    </ScrollAnimation>
                  </div>
                </ScrollAnimation>
              </div>
            </div>
          </div>
        </section>

        {/* faq Section */}
        <section className="faq_section">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <ScrollAnimation animateIn="slideInUp">
                  <h3 className="main_title_small">Explain our Platform</h3>
                </ScrollAnimation>
              </div>
              <div className="col-lg-12">
                <ScrollAnimation animateIn="slideInUp">
                  <h2 className="main_title">FAQ</h2>
                </ScrollAnimation>
                <ScrollAnimation animateIn="slideInUp">
                  <p className="inner_para">
                    You can send email with your questions and we'll give your
                    answer
                  </p>
                </ScrollAnimation>
                <div className="row">
                  <div className="col-md-12 col-lg-10 mx-auto">
                    <div className="accordion" id="faq_accodian">
                      <div className="accordion-item">
                        <h2 className="accordion-header" id="headingOne">
                          <button
                            className="accordion-button"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapseOne"
                            aria-expanded="true"
                            aria-controls="collapseOne"
                          >
                            What is Bonjour?
                          </button>
                        </h2>
                        <div
                          id="collapseOne"
                          className="accordion-collapse collapse show"
                          aria-labelledby="headingOne"
                          data-bs-parent="#faq_accodian"
                        >
                          <div className="accordion-body">
                            <p>
                              Bonjour is a decentralized networking platform
                              that operates on the BSC blockchain. It offers
                              easy earning opportunities for members through
                              referrals.
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="accordion-item">
                        <h2 className="accordion-header" id="headingTwo">
                          <button
                            className="accordion-button collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapseTwo"
                            aria-expanded="false"
                            aria-controls="collapseTwo"
                          >
                            {" "}
                            How many levels of total income streams are there
                            with Bonjour?
                          </button>
                        </h2>
                        <div
                          id="collapseTwo"
                          className="accordion-collapse collapse"
                          aria-labelledby="headingTwo"
                          data-bs-parent="#faq_accodian"
                        >
                          <div className="accordion-body">
                            <p>
                              Bonjour offers 7 levels of income, including 4
                              major streams: global pool income, level income,
                              direct referral income, and Rate of Return.
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="accordion-item">
                        <h2 className="accordion-header" id="headingThree">
                          <button
                            className="accordion-button collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapseThree"
                            aria-expanded="false"
                            aria-controls="collapseThree"
                          >
                            {" "}
                            What is the minimum investment required to join
                            Bonjour?
                          </button>
                        </h2>
                        <div
                          id="collapseThree"
                          className="accordion-collapse collapse"
                          aria-labelledby="headingThree"
                          data-bs-parent="#faq_accodian"
                        >
                          <div className="accordion-body">
                            <p>
                              Bonjour has a minimum investment requirement of
                              $50.
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="accordion-item">
                        <h2 className="accordion-header" id="headingFour">
                          <button
                            className="accordion-button collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapseFour"
                            aria-expanded="false"
                            aria-controls="collapseFour"
                          >
                            {" "}
                            How secure is my investment with Bonjour?
                          </button>
                        </h2>
                        <div
                          id="collapseFour"
                          className="accordion-collapse collapse"
                          aria-labelledby="headingFour"
                          data-bs-parent="#faq_accodian"
                        >
                          <div className="accordion-body">
                            <p>
                              Your investment with Bonjour is 100% secure and
                              highly profitable. Additionally, Bonjour is a
                              highly secure BSC-based DEX Matrix platform,
                              offering multi-layer security protection for your
                              invested funds.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* <div className="text-center">
              <button className="primary_btn">READ MORE</button>
            </div> */}
          </div>
        </section>
        <Footer />
      </div>
      {/* Bonjour Presale Modal */}
      {/* <div className="modal fade primary_modal" id="presale_info">
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-body">
              <div className="text-end">
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <h2>Token Presale Starts Soon</h2>
              <img
                src={require("../assets/images/bnjr_token.png")}
                className="img-fluid wallet_icon"
                alt="Icon"
              />
              <h2>Bonjour Token</h2>
              <div className="text-center mt-4 mb-3">
                <button className="primary_btn">
                  <a href="https://presale.webonjour.com/" target="_blank">
                    More Info
                  </a>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div> */}

      {/* Bonjour Autopool Modal */}
      <div className="modal fade autopool_modal" id="autopool_info">
        <div className="modal-dialog modal-dialog-centered modal-xl">
          <div className="modal-content">
            <div className="modal-body">
              <div className="text-end">
                <button
                  type="button"
                  className="btn-close autopool_modal_close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <img
                src={require("../assets/images/logo_large.png")}
                className="img-fluid"
                alt="Icon"
              />
              <div className="bonjour_modal_full bonjour_modal_full_chg">
                <div className="bonjour_presale_modalinfo">
                  <h1>Here’s the catch !</h1>
                  <h1 className="duplicate">Here’s the catch !</h1>
                  <h2>AN OPPORTUNITY TO ADD 15% TO YOUR INCOME !</h2>
                  <p className="popup_para">
                    If an user invests in these three arenas at the same time,
                    by adding one ID in a single day, you will receive 10%, 5%
                    income through Bonjour Plan & Autopool
                  </p>
                  <div className="row">
                    <div className="col-lg-4">
                      <div className="box">
                        <img
                          src={require("../assets/images/one.png")}
                          className="img-fluid"
                          alt="Icon"
                        />
                        <h5>Basic Bonjour Plan</h5>
                        <p>Minimum 50$ to Maximum 500$</p>
                      </div>
                    </div>
                    <div className="col-lg-4">
                      <div className="box">
                        <img
                          src={require("../assets/images/two.png")}
                          className="img-fluid"
                          alt="Icon"
                        />
                        <h5>Autopool </h5>
                        <p>Minimum 50$ to Maximum 500$</p>
                      </div>
                    </div>
                    <div className="col-lg-4">
                      <div className="box">
                        <img
                          src={require("../assets/images/three.png")}
                          className="img-fluid"
                          alt="Icon"
                        />
                        <h5>Tokens</h5>
                        <p>Minimum 50$ to Maximum 500$</p>
                      </div>
                    </div>
                  </div>
                  <p className="popup_para blw">
                    The Consecutive users will receive 5% to 10% of income based
                    on your referral levels.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Bonjour Autopool Modal */}
      {/* <div className="modal fade autopool_modal" id="autopool_info">
        <div className="modal-dialog modal-dialog-centered modal-xl">
          <div className="modal-content">
            <div className="modal-body">
              <div className="text-end">
                <button
                  type="button"
                  className="btn-close autopool_modal_close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <img
                src={require("../assets/images/logo_large.png")}
                className="img-fluid"
                alt="Icon"
              />
              <div className="bonjour_modal_full">
                <div className="bonjour_autopool_modalinfo">
                  <h1>Autopool</h1>
                  <h2 className="fontadj">
                    Invest 100 $ to 500 $ you will get an{" "}
                    <span className="airdrop_txt">"Airdrop"</span>
                    <span className="d-block">(Apr 04 to Apr 11 2023)</span>
                    of <span className="airdrop_txt">25000 BNJR</span>
                  </h2>
                  <h2 className="fontadj">
                    Min & Max investment is{" "}
                    <span className="d-block">10 $ to 500 $</span>
                  </h2>
                </div>
                <div className="bonjour_presale_modalinfo">
                  <h1>Presale is live now</h1>
                  <h2 className="fontadj">
                    only limited amount of tokens <br />
                    will ends on <span className="d-block">07.04.2023</span>
                  </h2>
                  <a href="https://presale.webonjour.com/" target="_blank">
                    <button className="primary_btn buttn" type="button">
                      BUYNOW
                    </button>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> */}

      {/* Bonjour Autopool Modal */}
      {/* <div className="modal fade autopoollive_modal" id="autopool_info">
        <div className="modal-dialog modal-dialog-centered modal-lg">
          <div className="modal-content">
            <div className="modal-body">
              <div className="autopoolban">
                <div className="autopool_box">
                  <h1>Autopool</h1>
                </div>
              </div>
              {<Countdown date={`2023-03-31T20:00:59`} renderer={renderer} />}
              <div className="text-center mt-5">
                <a href="https://webonjour.com/bonjourpool" target="_blank">
                  <button className="primary_btn buttn" type="button">
                    Click Here
                  </button>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div> */}
    </div>
  );
}
