import axios from "axios";
import config from "../config/config";
// import { getAuthToken } from "../lib/localStorage";
// axios.defaults.headers.common["Authorization"] = getAuthToken();

export const getAddress = async (token, dispatch) => {
  try {
    let respData = await axios({
      method: "get",
      url: `${config.Server_URL}/api/getAddress`,
    });
    return {
      loading: false,
      userValue: respData.data.addressData,
    };
  } catch (err) {
    return {
      loading: false,
      error: err.response.data.errors,
    };
  }
};

export const addLottery = async (data) => {
  try {
    let respData = await axios({
      method: "post",
      url: `${config.Server_URL}/api/addLottery`,
      data,
    });

    return {
      loading: false,
    };
  } catch (err) {
    return {
      loading: false,
      error: err.response.data.errors,
    };
  }
};

export const addLotterySettings = async (data) => {
  try {
    let respData = await axios({
      method: "post",
      url: `${config.Server_URL}/api/addLotterySettings`,
      data,
    });

    return {
      loading: false,
    };
  } catch (err) {
    return {
      loading: false,
      error: err.response.data.errors,
    };
  }
};

export const completeTicket = async (data, dispatch) => {
  console.log("yes");
  try {
    let respData = await axios({
      method: "post",
      url: `${config.Server_URL}/api/completeTicket`,
      data,
    });
    return {
      loading: false,
      userValue: respData.data.userValue,
    };
  } catch (err) {
    return {
      loading: false,
      error: err.response.data.errors,
    };
  }
};

export const closeComTicket = async (data, dispatch) => {
  console.log("yessaran");
  try {
    let respData = await axios({
      method: "post",
      url: `${config.Server_URL}/api/closeComTicket`,
      data,
    });

    console.log(respData, "respDatarespDatarespDatarespDatarespData");
    return {
      loading: false,
      userValue: respData.data.userValue,
    };
  } catch (err) {
    return {
      loading: false,
      error: err.response.data.errors,
    };
  }
};

export const getlotterylist = async (token, dispatch) => {
  console.log("yes");
  try {
    let respData = await axios({
      method: "get",
      url: `${config.Server_URL}/api/getlotterylist`,
    });
    return {
      loading: false,
      userValue: respData.data.addressData,
    };
  } catch (err) {
    return {
      loading: false,
      error: err.response.data.errors,
    };
  }
};

export const updateTicketPrice = async (data) => {
  try {
    let respData = await axios({
      method: "post",
      url: `${config.Server_URL}/api/updateTicketPrice`,
      data,
    });
    return {
      loading: false,
      userValue: respData.data.data,
    };
  } catch (err) {
    return {
      loading: false,
      error: err.response.data.errors,
    };
  }
};

export const updatePrize = async () => {
  try {
    let respData = await axios({
      method: "get",
      url: `${config.Server_URL}/api/updatePrize`,
    });
    console.log(respData, "respData");
    return {
      loading: false,
      result: respData.data,
    };
  } catch (err) {
    return {
      loading: false,
      error: err.response.data.errors,
    };
  }
};
