import axios from 'axios';
import config from '../config/config';
import Web3 from "web3";
import WalletConnectProvider from "@walletconnect/web3-provider";
import bonjourabi from "../ABI/bonjourABI";
import busdabi from "../ABI/busdABI";
import {
  Multicall,
  ContractCallResults,
  ContractCallContext,
} from 'ethereum-multicall';
import BigNumber from "bignumber.js";

export const getCurAddr = async () => {
  if(localStorage.getItem("wallettype") && localStorage.getItem("wallettype")!=null && localStorage.getItem("wallettype")!=undefined && localStorage.getItem("wallettype")!="" && localStorage.getItem("wallettype")=="trust"){
    try{
      var web3 = new Web3(window.ethereum);
      const provider = new WalletConnectProvider({
        rpc: {
          56: "https://bsc-dataseed.binance.org/",
        },
        chainId: 56
      });

      provider.on("connect", () => {
      });
      await provider.enable();
      web3 = new Web3(provider);
      var network = await web3.eth.net.getId();
      var result = await web3.eth.getAccounts();
      if(result[0]!=undefined){
        if (config.networkVersion  == network) {
          localStorage.setItem("wallettype","trust")
          localStorage.setItem("account",result[0]);
          let bnbbalance = await web3.eth.getBalance(result[0]);
          var busdContract = new web3.eth.Contract(busdabi, config.busdcontract);
          let busdbalance = await busdContract.methods.balanceOf(result[0]).call();
          const multicall = new Multicall({
              web3Instance: web3,
          });
          var contractCallContext = [
            {
                reference: "details",
                contractAddress: config.bonjourcontract,
                abi: bonjourabi,
                calls: [
                    {
                        reference: "userprofile",
                        methodName: "users",
                        methodParameters: [localStorage.getItem("account")],
                    },
                    {
                        reference: "minInvestment",
                        methodName: "minInvestment",
                        methodParameters: [],
                    },
                    {
                        reference: "maxInvestment",
                        methodName: "maxInvestment",
                        methodParameters: [],
                    },
                    {
                        reference: "minWithdrawAmount",
                        methodName: "minWithdrawAmount",
                        methodParameters: [],
                    },
                ],
            },
          ];
          const results = await multicall.call(contractCallContext);
              var userdetails = await getFormatMulticall(results, "userprofile", 0);
               let userexist = true;
              let userid = await bignumber(userdetails[1]);
              let levelIncomeEligible = await bignumber(userdetails[2]);
              let referralid = await bignumber(userdetails[3]);
              let investmentAmount = await bignumber(userdetails[4]);
              let earnedAmount = await bignumber(userdetails[5]);
              let levelIncome = await bignumber(userdetails[6]);
              let globalPoolIncome = await bignumber(userdetails[7]);
              let referralCount = await bignumber(userdetails[8]);
              let referralIncome = await bignumber(userdetails[9]);
              let teamVolume = await bignumber(userdetails[10]);
              let data_user = {
                "isExist" : userdetails[0],
                "userid"    : userid,
                "levelIncomeEligible" : parseFloat(levelIncomeEligible)/1e18 ,
                "referralid" : referralid,
                "investmentAmount" : parseFloat(investmentAmount)/1e18 ,
                "earnedAmount" : parseFloat(earnedAmount)/1e18 ,
                "levelIncome" : parseFloat(levelIncome)/1e18,
                "globalPoolIncome" : parseFloat(globalPoolIncome)/1e18,
                "referralCount" : referralCount,
                "referralIncome" : parseFloat(referralIncome)/1e18,
                "teamVolume" : parseFloat(teamVolume)/1e18
              }
              let min_value = await getFormatMulticall(results, "minInvestment", 0);
              let max_value = await getFormatMulticall(results, "maxInvestment", 0);
              min_value = await bignumber(min_value[0])
              max_value = await bignumber(max_value[0])
              let min_with_value = await getFormatMulticall(results, "minWithdrawAmount", 0);
              min_with_value = await bignumber(min_with_value[0])
              var data = {
                 address : result[0],
                 busdvalue  : busdbalance,
                 provider : provider,
                 userdetails : data_user,
                 minvalue : (parseFloat(min_value)/1e18).toFixed(2),
                 maxvalue : (parseFloat(max_value)/1e18).toFixed(2),
                 minwithdrawvalue : parseFloat(min_with_value)/1e18
              }
              return data
        } else {
         await provider.disconnect();
        }
     }
    }catch(err){
      localStorage.clear();
      window.location.reload()
    }
  }
  else if(localStorage.getItem("wallettype") && localStorage.getItem("wallettype")!=null && localStorage.getItem("wallettype")!=undefined && localStorage.getItem("wallettype")!="" && localStorage.getItem("wallettype")=="metamask"){
   if (window.ethereum) {
      var web3 = new Web3(window.ethereum);
      try {
        if (typeof web3 !== "undefined") {
          await window.ethereum.enable()
          let web3 = new Web3(window.ethereum);
          if (window.ethereum) {
            let netid = await web3.eth.net.getId();
            if (
              netid ==
              config.networkVersion
            ) {
              var result = await web3.eth.getAccounts();
              var busdContract = new web3.eth.Contract(busdabi, config.busdcontract);
              let busdbalance = await busdContract.methods.balanceOf(result[0]).call();
              if(localStorage.getItem("account")){
                localStorage.setItem("wallettype","metamask")
                localStorage.setItem("account",result[0]);
              }
              const multicall = new Multicall({
                  web3Instance: web3,
              });
              var contractCallContext = [
                {
                    reference: "details",
                    contractAddress: config.bonjourcontract,
                    abi: bonjourabi,
                    calls: [
                        {
                            reference: "userprofile",
                            methodName: "users",
                            methodParameters: [localStorage.getItem("account")],
                        },
                        {
                            reference: "minInvestment",
                            methodName: "minInvestment",
                            methodParameters: [],
                        },
                        {
                            reference: "maxInvestment",
                            methodName: "maxInvestment",
                            methodParameters: [],
                        },
                        {
                            reference: "minWithdrawAmount",
                            methodName: "minWithdrawAmount",
                            methodParameters: [],
                        },
                    ],
                },
              ];
              const results = await multicall.call(contractCallContext);
              var userdetails = await getFormatMulticall(results, "userprofile", 0);
               let userexist = true;
              let userid = await bignumber(userdetails[1]);
              let levelIncomeEligible = await bignumber(userdetails[2]);
              let referralid = await bignumber(userdetails[3]);
              let investmentAmount = await bignumber(userdetails[4]);
              let earnedAmount = await bignumber(userdetails[5]);
              let levelIncome = await bignumber(userdetails[6]);
              let globalPoolIncome = await bignumber(userdetails[7]);
              let referralCount = await bignumber(userdetails[8]);
              let referralIncome = await bignumber(userdetails[9]);
              let teamVolume = await bignumber(userdetails[10]);
              let data_user = {
                "isExist" : userdetails[0],
                "userid"    : userid,
                "levelIncomeEligible" : parseFloat(levelIncomeEligible)/1e18 ,
                "referralid" : referralid,
                "investmentAmount" : parseFloat(investmentAmount)/1e18 ,
                "earnedAmount" : parseFloat(earnedAmount)/1e18 ,
                "levelIncome" : parseFloat(levelIncome)/1e18,
                "globalPoolIncome" : parseFloat(globalPoolIncome)/1e18,
                "referralCount" : referralCount,
                "referralIncome" : parseFloat(referralIncome)/1e18,
                "teamVolume" : parseFloat(teamVolume)/1e18
              }
              let min_value = await getFormatMulticall(results, "minInvestment", 0);
              let max_value = await getFormatMulticall(results, "maxInvestment", 0);
              min_value = await bignumber(min_value[0])
              max_value = await bignumber(max_value[0])
              let min_with_value = await getFormatMulticall(results, "minWithdrawAmount", 0);
              min_with_value = await bignumber(min_with_value[0])
              var data = {
                 address : result[0],
                 busdvalue  : busdbalance,
                 userdetails : data_user,
                 minvalue : parseFloat(min_value)/1e18,
                 maxvalue : parseFloat(max_value)/1e18,
                 minwithdrawvalue : parseFloat(min_with_value)/1e18
              }
              return data 
            }
          }
        }
      }catch(err){
        localStorage.clear();
        console.log(err,"===========errrrrrrrrrrrdata")
     }
    }
  }
}

export const updateregister = async (payload) => {
  try {
    let resp = await axios({
      method: "post",
      url: `${config.Server_URL}api/join_now`,
      data: payload,
    });
    return {
      data: resp.data,
    };
  } catch (err) {

  }
};

export const getlasttransaction = async (payload) => {
   try {
    let resp = await axios({
      method: "post",
      url: `${config.Server_URL}api/getlasttransaction`,
      data: payload,
    });
    return {
      data: resp.data,
    };
  } catch (err) {

  }
}

export const get_view_count = async (payload) => {
   try {
    let resp = await axios({
      method: "post",
      url: `${config.Server_URL}api/get_view_count`,
      data: payload,
    });
    return {
      data: resp.data,
    };
  } catch (err) {

  }
}

export const checkview = async (payload) => {
   try {
    let resp = await axios({
      method: "post",
      url: `${config.Server_URL}api/checkview`,
      data: payload,
    });
    return {
      data: resp.data,
    };
  } catch (err) {

  }
}

export const updatereferral = async (payload) => {
   try {
    let resp = await axios({
      method: "post",
      url: `${config.Server_URL}api/updatereferral`,
      data: payload,
    });
    return {
      data: resp.data,
    };
  } catch (err) {

  }
}

export const checkreferal = async (payload) => {
   try {
    let resp = await axios({
      method: "post",
      url: `${config.Server_URL}api/checkreferal`,
      data: payload,
    });
    return {
      data: resp.data,
    };
  } catch (err) {

  }
}

export const addactivity = async (payload) => {
  try {
    let resp = await axios({
      method: "post",
      url: `${config.Server_URL}api/add_activity`,
      data: payload,
    });
    return {
      data: resp.data,
    };
  } catch (err) {

  }
};

export const getuserrecords = async (payload) => {
  try {
    let resp = await axios({
      method: "post",
      url: `${config.Server_URL}api/getuserrecords`,
      data: payload,
    });
    return {
      data: resp.data,
    };
  } catch (err) {

  }
}

export const gettotaluser = async (payload) => {
  try {
    let resp = await axios({
      method: "post",
      url: `${config.Server_URL}api/gettotaluser`,
      data: payload,
    });
    return {
      data: resp.data,
    };
  } catch (err) {

  }
}

export const add_Address = async (payload) => {
  try {
    let resp = await axios({
      method: "post",
      url: `${config.Server_URL}api/addclaimlist`,
      data: payload,
    });
    return {
      data: resp.data,
    };
  } catch (err) {

  }
}

export const remove_Address = async (payload) => {
  try {
    let resp = await axios({
      method: "post",
      url: `${config.Server_URL}api/removeclaimlist`,
      data: payload,
    });
    return {
      data: resp.data,
    };
  } catch (err) {

  }
}

export const claim_list = async (payload) => {
  try {
    let resp = await axios({
      method: "get",
      url: `${config.Server_URL}api/getclaimlist`,
      data: payload,
    });
    return {
      data: resp.data,
    };
  } catch (err) {

  }
}

export const filter_event = async (payload) => {
  try {
    let resp = await axios({
      method: "post",
      url: `${config.Server_URL}api/filter_event`,
      data: payload,
    });
    return {
      data: resp.data,
    };
  } catch (err) {

  }
}

function getFormatMulticall(results, name, pos) {
  try {
      var index = results && results.results && results.results.details && 
          results.results.details.callsReturnContext &&
          results.results.details.callsReturnContext.findIndex(val => val.reference == name);
      var returnVal = results.results.details.callsReturnContext[index] &&
          results.results.details.callsReturnContext[index].returnValues  ?
          results.results.details.callsReturnContext[index].returnValues : "";
      return returnVal;
  } catch (err) {
      console.log(err,"==+++++++")
      return "";
  }
}

async function bignumber(inputs){
  let ten =new BigNumber(inputs.hex,16);
  var value = ten.toString(10);
  return value;
}