let NODE_ENV      = 'demo';


let Front_URL = "";
let Base_URL = "";
let rpcURL = "";
let decimalvalues = 1000000000000000000;
let toFixed = 4;
let networkVersion = "";
let bscurl = "";  
let adminAddress = "";
let bonjourcontract = "";
let busdcontract = "";
let bnbtousd = "";
let chainId = "";
let Server_URL = "";
const ownerAddr = "0x49E1a781BffF290350d0EBA905D42823F2308F39";

let toasterOption = {
  position: "top-right",
  autoClose: 2000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: false,
  draggable: true,
  progress: undefined,
};

var lottery = "";
var lotterAdmin = "";
var lotteryToken = "";

var singleContract = "";
var WbnbtokenAddr = "";
var PankukuAddr = "";
var ContractAddress = "";

if (NODE_ENV === "production") {
  Front_URL = "https://webonjour.com/";
  Server_URL = "https://api.webonjour.com/";
  networkVersion = 56;
  rpcURL = "https://bsc-dataseed.binance.org/";
  bscurl = "https://bscscan.com/";
  adminAddress = "0x9a427cbbac2c87cc58b953a049132708ab466013";
  bonjourcontract = "0x02AD27A0Cd5fF292ad81007dFEeBA364e896C73C";
  busdcontract = "0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56";
  bnbtousd = "0x0567F2323251f0Aab15c8dFb1967E4e8A7D42aeE";
  chainId = 56;
} else if (NODE_ENV === "demo") {
  Front_URL = "https://bonjour-autopool-demo.pages.dev/";
  Server_URL = "http://demoapi.webonjour.com/";
  networkVersion = 97;
  rpcURL = "https://data-seed-prebsc-1-s3.binance.org:8545/";
  bscurl = "https://testnet.bscscan.com/";
  adminAddress = "0x373f886904048975034488320e37d171e092c135";
  bonjourcontract = "0xc656FD21c07763f83b869ccbD57DB09779eCC017"; //0x908fDed1E68C2ADf0CB4a5f8684ADC747F25b64A
  busdcontract = "0x8CE7720fD183AeC96b676FD8250724b05b0d7a6F";
  bnbtousd = "0x0567F2323251f0Aab15c8dFb1967E4e8A7D42aeE";
  chainId = 97;
  //
  lottery = "0x68821dCc65c6719372F37DCd48bEf0672048c1F5";
  lotterAdmin = "0x49E1a781BffF290350d0EBA905D42823F2308F39";
  lotteryToken = "0x498532dc25045289E34A5Be1A050b3B30C024fcc";

  singleContract = "0xF739B96ad8B4efcebB7EcAffed2f560ACDB64517";
  WbnbtokenAddr = "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c";
  PankukuAddr = "0x1dB3c1545df0c705cceEf4F7d33EFE8854bB8254";
  ContractAddress = "0x68821dCc65c6719372F37DCd48bEf0672048c1F5";
} else {
  Front_URL = "http://localhost:3000/";
  Server_URL = "http://localhost:2053/";
  networkVersion = 97;
  rpcURL = "https://data-seed-prebsc-1-s3.binance.org:8545/";
  bscurl = "https://testnet.bscscan.com/";
  adminAddress = "0x373f886904048975034488320e37d171e092c135";
  bonjourcontract = "0xc656FD21c07763f83b869ccbD57DB09779eCC017"; //0x908fDed1E68C2ADf0CB4a5f8684ADC747F25b64A
  busdcontract = "0x8CE7720fD183AeC96b676FD8250724b05b0d7a6F";
  bnbtousd = "0x0567F2323251f0Aab15c8dFb1967E4e8A7D42aeE";
  chainId = 97;
  //
  lottery = "0x06c6618e1ac8cfdf3f42523b32fba90059cd93c7";
  lotterAdmin = "0x49E1a781BffF290350d0EBA905D42823F2308F39";
  lotteryToken = "0x498532dc25045289E34A5Be1A050b3B30C024fcc";

  singleContract = "0xF739B96ad8B4efcebB7EcAffed2f560ACDB64517";
  WbnbtokenAddr = "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c";
  PankukuAddr = "0x1dB3c1545df0c705cceEf4F7d33EFE8854bB8254";
  ContractAddress = "0x06c6618e1ac8cfdf3f42523b32fba90059cd93c7";
}

let key = {
  Front_URL: Front_URL,
  Server_URL: Server_URL,
  decimalvalues: decimalvalues,
  toFixed: toFixed,
  rpcURL: rpcURL,
  networkVersion: networkVersion,
  toasterOption: toasterOption,
  adminAddress: adminAddress,
  bonjourcontract: bonjourcontract,
  busdcontract: busdcontract,
  bnbtousd: bnbtousd,
  bscurl: bscurl,
  chainId: chainId,
  ownerAddr: ownerAddr,
  lotteryToken: lotteryToken,
  lottery: lottery,
  lotterAdmin: lotterAdmin,
  ContractAddress: ContractAddress,
  PankukuAddr: PankukuAddr,
  singleContract: singleContract,
  WbnbtokenAddr: WbnbtokenAddr,
};

export default key;
