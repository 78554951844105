// import lib
import isEmpty from "../../config/isEmpty";

const validation = (value) => {
  let errors = {};
  console.log(value.endTime, "value.endTime");

  if (isEmpty(value.endTime)) {
    errors.endTime = "Please enter end time";
  } else if (isNaN(value.endTime)) {
    errors.endTime = "Invalid end time";
  }

  if (isEmpty(value.ticketPice)) {
    errors.ticketPice = "Please enter ticket price";
  } else if (isNaN(value.ticketPice)) {
    errors.ticketPice = "Invalid ticket price";
  } else if (Math.sign(value.ticketPice) === -1) {
    errors.ticketPice = "The ticket price must be postive number";
  }

  if (isEmpty(value.rew1)) {
    errors.rew1 = "Please enter reward 1";
  } else if (isNaN(value.rew1)) {
    errors.rew1 = "Invalid reward 1";
  }

  if (isEmpty(value.rew2)) {
    errors.rew2 = "Please enter reward 2";
  } else if (isNaN(value.rew2)) {
    errors.rew2 = "Invalid reward 2";
  }

  if (isEmpty(value.rew3)) {
    errors.rew3 = "Please enter reward 3";
  } else if (isNaN(value.rew3)) {
    errors.rew3 = "Invalid reward 3";
  }

  if (isEmpty(value.rew4)) {
    errors.rew4 = "Please enter reward 4";
  } else if (isNaN(value.rew4)) {
    errors.rew4 = "Invalid reward 4";
  }

  if (isEmpty(value.rew5)) {
    errors.rew5 = "Please enter reward 5";
  } else if (isNaN(value.rew5)) {
    errors.rew5 = "Invalid reward 5";
  }

  if (isEmpty(value.rew6)) {
    errors.rew6 = "Please enter reward 6";
  } else if (isNaN(value.rew6)) {
    errors.rew6 = "Invalid reward 6";
  }

  return errors;
};

export default validation;
